import React from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch,} from 'react-router-dom';
import {DatabasePage} from '../../database/DatabasePage';
import {MainSearchPage} from '../../mainSearch/MainSearchPage';
import {NotFoundPage} from '../../notFound/NotFoundPage';
import {AuthPage} from '../../auth/AuthPage';

export const AppRouter: React.FC = () => {

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Redirect to="/main"/>
        </Route>

        <Route exact path="/main">
          <MainSearchPage/>
        </Route>

        <Route exact path="/database/:id">
          <DatabasePage/>
        </Route>

        <Route exact path="/auth">
          <AuthPage/>
        </Route>

        <Route>
          <NotFoundPage/>
        </Route>
      </Switch>
    </Router>
  );
};