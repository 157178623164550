import styled from 'styled-components/macro';
import {Form} from 'formik';

const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 1.5rem;
`;

const FieldsRow = styled.div`
  display: flex;
  justify-content: space-between;
  
  & > div:not(:last-child) {
    margin-right: 1rem;
  }
`

const Label = styled.div`
  margin-bottom: .5rem;
  padding-left: .5rem;
  color: ${({theme}) => theme.palette.primary.main}
`;

export const FormStyles = {
  FieldsContainer,
  FieldsRow,
  Label
}