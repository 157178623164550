import React from 'react';
import styled from 'styled-components/macro';
import {NotificationSingle} from './NotificationSingle';
import {NotificationInterface} from '../../../features/app/redux/appTypes';

export const NotificationProvider: React.FC<Props> = props => {

  const {
    notifications
  } = props;

  return (
    <NotificationWrapper>
      {notifications.map(note => {
        return <NotificationSingle
          key={note.id}
          id={note.id}
          message={note.message}
          type={note.type}
        />;
      })}
    </NotificationWrapper>
  );
};

// Styles
const NotificationWrapper = styled.div`
  position: fixed;
  bottom: 1rem;
  right: 1rem;

  width: 300px;

  z-index: 1000;
`;

// Types
type Props = {
  notifications: Array<NotificationInterface>
}