import React from 'react'
import {ReactComponent as ArrowLeft} from '../../assets/svg/arrows/arrow-left.svg'
import {ReactComponent as ArrowRight} from '../../assets/svg/arrows/arrow-right.svg'
import {ReactComponent as ArrowDown} from '../../assets/svg/arrows/arrow-down.svg'

export const Arrow: React.FC<Props> = props => {

    const {
        direction,
        onClick,
    } = props

    if (!direction || direction === 'right') return <ArrowRight onClick={onClick}/>
    if (direction === 'down') return <ArrowDown onClick={onClick}/>
    if (direction === 'left') return <ArrowLeft onClick={onClick}/>

    return null
}

// Types
type Props = {
    direction?: string;
    onClick?: () => void,
}