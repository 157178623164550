import React, {useState} from 'react';
import {flatChildren, newLineAfterSlash} from '../../../../../helpers/dataHelpers';
import styled from 'styled-components/macro';
import {useSelector} from 'react-redux';
import {AppState} from '../../../../app/redux/appDuck';
import {RootState} from '../../../../../core/redux/store';
import {Modal} from '../../../../../core/components/modal/Modal';
import {ButtonPrimaryOutlined} from '../../../../../core/components/ui-elements/buttons';
import {IndustryOptions} from '../../../../mainSearch/components/content/IndustryOptions';
import {DatabaseState} from '../../../redux/databaseDuck';

export const IndustryPerformanceChartHorizontal: React.FC<Props> = props => {

  const {
    industryOptionsShowResultButtonAction
  } = props;

  // Selectors
  const {dictionaries} = useSelector<RootState, AppState>(state => state.app);
  const {databaseData} = useSelector<RootState, DatabaseState>(state => state.database);

  const [modalActive, setModalActive] = useState(false);

  const toggleModal = () => {
    setModalActive(!modalActive);
  };

  // Calculate the highest rating.
  let highestRating = 0;
  databaseData.industryGraph.forEach((item: any) => {
    if (item.rating > highestRating) {
      highestRating = item.rating;
    }
  });

  return (<>
    <Modal
      show={modalActive}
      toggleModal={toggleModal}
      content={<IndustryOptions
        showResult={industryOptionsShowResultButtonAction ? industryOptionsShowResultButtonAction : () => {
        }}/>}
      width={90}
    />
    <ButtonPrimaryOutlinedStyled onClick={toggleModal}>Choose another industry</ButtonPrimaryOutlinedStyled>
      <PerformanceChartContainer>
        <PerformanceChart>
          <PerformanceChartBackground>
            <Line/><Line/><Line/><Line/><Line/><Line/><Line/><Line/><Line/><Line/><Line/>
          </PerformanceChartBackground>
          <ChartBarWrapper>
            {databaseData.industryGraph.map((graph: any, index: any) => (
              <ChartBar
                key={index}
                rating={graph.rating}
                highestRating={highestRating}
                isSelected={graph.isSelected}
              >
                <IndustryName>
                  {dictionaries && newLineAfterSlash(flatChildren(dictionaries.industries).find(dict => graph.industryId === dict.id)!.name)}
                </IndustryName>
                {graph.isSelected && (
                  <Rating>{graph.rating}</Rating>
                )}
              </ChartBar>
            ))}
          </ChartBarWrapper>
        </PerformanceChart>
      </PerformanceChartContainer>
  </>);
};

// Styles
const ButtonPrimaryOutlinedStyled = styled(ButtonPrimaryOutlined)`
  margin-bottom: 2rem;
`;

const PerformanceChartContainer = styled.div`
  padding-bottom: 5rem;
`;

const PerformanceChart = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-around;

  position: relative;

  height: 50rem;
`;

const PerformanceChartBackground = styled.div`
  display: flex;
  justify-content: space-between;

  position: absolute;

  height: 100%;
  width: 100%;
`;

const Line = styled.div`
  height: 100%;
  width: 1px;

  background-color: ${({theme}) => theme.palette.custom.primary};
`;

const ChartBarWrapper = styled.div`
  //position: relative;
  
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  
  height: 100%;
  width: 100%;
`

const ChartBar = styled.div<{ rating: number, highestRating: number, isSelected: boolean }>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  width: ${({rating, highestRating}) => `${(rating * 100 / highestRating)}%`};
  height: 5%;
  border-radius: ${({theme}) => `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`};
  
  color: ${({theme, isSelected}) => isSelected ? theme.palette.common.white : theme.palette.common.black};

  background-color: ${({theme, isSelected}) =>
          isSelected ? theme.palette.custom.secondaryAccent : theme.palette.custom.primary};
`;

const Rating = styled.div`
  padding-right: 1rem;
  
  text-align: center;
  color: ${({theme}) => theme.palette.common.white};

  font-size: 1.3rem;
`;

const IndustryName = styled.div`
  padding-left: 1rem;
  
  font-size: 1rem;
  font-weight: 600;
`;

// Types
type Props = {
  industryOptionsShowResultButtonAction?: any
}