import React from 'react';
import styled from 'styled-components/macro';
import {LogoCombined} from './LogoCombined';
import {useHistory} from 'react-router-dom';

export const LogoHeader: React.FC = () => {

  const history = useHistory();

  return (
    <LogoStyled onClick={() => history.push('/main')}/>
  )
}

// Styles
const LogoStyled = styled(LogoCombined)`
  width: 5rem;
  
  &:hover {
    cursor: pointer;
  }
`;