import {SearchTab} from './mainSearchTypes';
import {APIUrl} from '../../../core/api/api';

export enum MainSearchActionType {
  TAB_SET = 'mainSearch/TAB_SET',

  LOOKUP_LOAD = 'mainSearch/LOOKUP_LOAD',
  LOOKUP_SUCCESS = 'mainSearch/LOOKUP_SUCCESS',
  LOOKUP_FAIL = 'mainSearch/LOOKUP_FAIL',

  LOOKUP_SAVE = 'mainSearch/LOOKUP_SAVE',
  LOOKUP_CLEAR_ALL = 'mainSearch/LOOKUP_CLEAR_ALL',
  LOOKUP_CLEAR_RESULTS = 'mainSearch/LOOKUP_CLEAR_RESULTS',

  // Industries content
  SELECT_INDUSTRY = 'mainSearch/SELECT_INDUSTRY'
}

export const mainSearchAction = {
  tabSet: (activeTab: SearchTab) => ({
    type: MainSearchActionType.TAB_SET as const,
    payload: {
      activeTab
    }
  }),
  lookupLoad: (url: APIUrl, params: string) => ({
    type: MainSearchActionType.LOOKUP_LOAD as const,
    payload: {
      url,
      params
    }
  }),
  lookupSuccess: (url: APIUrl, searchResult: any) => ({
    type: MainSearchActionType.LOOKUP_SUCCESS as const,
    payload: {
      url,
      searchResult
    }
  }),
  lookupFail: () => ({
    type: MainSearchActionType.LOOKUP_FAIL as const,
  }),
  lookupSave: (search: string) => ({
    type: MainSearchActionType.LOOKUP_SAVE as const,
    payload: {
      search
    }
  }),
  lookupClearAll: () => ({
    type: MainSearchActionType.LOOKUP_CLEAR_ALL as const
  }),
  lookupClearResults: () => ({
    type: MainSearchActionType.LOOKUP_CLEAR_RESULTS as const
  }),
  selectIndustry: (industryId: number) => ({
    type: MainSearchActionType.SELECT_INDUSTRY as const,
    payload: {
      industryId
    }
  })
};

export type MainSearchAction = ReturnType<typeof mainSearchAction.tabSet>
| ReturnType<typeof mainSearchAction.lookupLoad>
| ReturnType<typeof mainSearchAction.lookupSuccess>
| ReturnType<typeof mainSearchAction.lookupFail>
| ReturnType<typeof mainSearchAction.lookupSave>
| ReturnType<typeof mainSearchAction.lookupClearAll>
| ReturnType<typeof mainSearchAction.lookupClearResults>
| ReturnType<typeof mainSearchAction.selectIndustry>