import React, {memo} from 'react';
import styled from 'styled-components/macro';
import {Checkbox} from '../inputs/Checkbox';

export const OptionsGeneratorVisible: React.FC<Props> = memo(props => {

  const {
    option: {id, name, children},
    handleClick,
    handleChecked,
    isChild = false,
  } = props;

  return (<>
    <MenuOption
      onClick={() => handleClick(id)}
    >

      <Checkbox
        checked={handleChecked(id)}
        onClick={(e: any) => {e.stopPropagation()}}
      />

      <span>{name}</span>
    </MenuOption>

    {children && children.map(item => (
      <Child
        key={item.id}
        option={item}
        handleClick={handleClick}
        handleChecked={handleChecked}
      />
    ))}
  </>);
});

const Child: React.FC<ChildrenProps> = memo(props => {

  const {
    option: {id, name},
    handleClick,
    handleChecked
  } = props;

  return (<>
      <ChildOption
        onClick={() => handleClick(id)}
      >
        <Checkbox
          checked={handleChecked(id)}
          onClick={(e: any) => {
            e.stopPropagation();
          }}
        />

        <span>{name}</span>

      </ChildOption>
    </>
  );
});

// Styles
const MenuOption = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  font-size: .88rem;

  & :hover {
    cursor: pointer;
  }

  & > span {
    color: ${({theme}) => theme.palette.primary.main};
    font-weight: ${({theme}) => theme.typography.fontWeightBold};
  }
`;

const ChildOption = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  padding-left: 1.5rem;

  font-size: .88rem;

  & :hover {
    cursor: pointer;
  }
`;

// Types
type Props = {
  option: OptionType
  handleClick: (id: number) => void
  handleChecked: (id: number) => boolean
  isChild?: boolean // Needed only for visible list type.
}

type ChildrenProps = {
  option: OptionType
  handleClick: (id: number) => void
  handleChecked: (id: number) => boolean
}

export type OptionType = {
  id: number,
  name: string,
  children?: Array<OptionType>,
}