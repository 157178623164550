import React from 'react';
import 'fontsource-roboto';
import {AppRouter} from './router/AppRouter';
import {NotificationController} from './NotificationController';

export const App: React.FC = () => {

  return (<>
    <NotificationController/>
    <AppRouter/>
  </>);
};