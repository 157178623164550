import React from 'react';
import {Button, ButtonProps} from '@material-ui/core';
import styled from 'styled-components/macro';

export const ButtonPrimary: React.FC<CustomButtonProps> = props => {

  const {
    onClick,
    className,
    children,
    ...rest
  } = props;

  return (
    <ButtonPrimaryMain
      onClick={onClick}
      className={className}
      variant="contained"
      {...rest}
    >
      {children}
    </ButtonPrimaryMain>
  );
};

export const ButtonPrimaryOutlined: React.FC<CustomButtonProps> = props => {

  const {
    onClick,
    className,
    children,

    ...rest
  } = props;

  return (
    <ButtonPrimaryOutlinedMain
      onClick={onClick}
      className={className}
      variant="contained"
      {...rest}
    >
      {children}
    </ButtonPrimaryOutlinedMain>
  );
};


export const ButtonRounded: React.FC<CustomButtonProps> = props => {

  const {
    onClick,
    className,
    children,
    ...rest
  } = props;

  return (
    <ButtonRoundedMain
      onClick={onClick}
      className={className}
      variant="outlined"
      color="primary"
      {...rest}
    >
      {children}
    </ButtonRoundedMain>
  );
};

// Styles
const ButtonPrimaryMain = styled(Button)`
  font-family: ${({theme}) => theme.typography.fontFamilyAlt};
  font-weight: 600;
  color: ${({theme}) => theme.palette.common.white};
  background-color: ${({theme}) => theme.palette.common.black};

  border: 2px solid ${({theme}) => theme.palette.common.black};

  &:hover {
    color: ${({theme}) => theme.palette.common.black};
    background-color: ${({theme}) => theme.palette.custom.tertiary};

    border: 2px solid ${({theme}) => theme.palette.custom.tertiary};
  }

  &.MuiButton-contained.Mui-disabled {
    background-color: ${({theme}) => theme.palette.custom.primaryPale};
  }
`;

const ButtonPrimaryOutlinedMain = styled(Button)`
  font-family: ${({theme}) => theme.typography.fontFamilyAlt};
  font-weight: 600;
  color: ${({theme}) => theme.palette.common.black};
  background-color: ${({theme}) => theme.palette.custom.primaryPale};

  border: 2px solid ${({theme}) => theme.palette.common.black};

  &:hover {
    color: ${({theme}) => theme.palette.common.black};
    background-color: ${({theme}) => theme.palette.custom.tertiary};
  }

  &.MuiButton-contained.Mui-disabled {
    background-color: ${({theme}) => theme.palette.custom.primaryPale};
  }
`;

const ButtonRoundedMain = styled(Button)`
  padding: .4rem 2rem;

  font-family: ${({theme}) => theme.typography.fontFamilyAlt};

  border-radius: 30px;
`;

// Types
type CustomButtonProps = ButtonProps & {
  onClick?: any
  className?: string
  children: React.ReactNode
}