import {CircularProgress} from '@material-ui/core'
import React from 'react'

export const Preloader: React.FC<Props> = props => {

    const {
        className
    } = props

    return (
        <CircularProgress className={className}/>
    )
}

// Types
type Props = {
    className?: string
}