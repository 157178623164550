import React from 'react';
import ReactSpeedometer from 'react-d3-speedometer';
import {RadialRatingData} from '../ratings/RadialRating/RadialRating';
import {theme} from '../../styles/theme';


const ratingMatrix = ['D', 'C', 'CC', 'CCC', 'B', 'BB', 'BBB', 'A', 'AA', 'AAA'];

export const paintRating = (value: number): string => value <= 30 ? theme.palette.rating.problematic.cc :
  (value > 30 && value <= 60) ? theme.palette.rating.balanced.bb : theme.palette.rating.healthy.aa;

let customSegmentStops: any = [];
let segmentColors: any = [];
let customSegmentLabels: any = [];

ratingMatrix.forEach((rating, i) => {
  customSegmentLabels.push({
    text: rating,
    fontSize: '13px',
    position: 'OUTSIDE',
    color: 'theme.palette.rating.problematic.cc',
  });
  const multiplied = (i + 1) * 10;
  customSegmentStops.push(multiplied);
  segmentColors.push(paintRating(multiplied));
});
// TODO: remove workaround with err about segLabs have to be of length 9
customSegmentLabels.shift();


// NOTE: This function is needed only because we don't have quality inside country data.
const calculateQuality = (data: any) => {
  if (data.rating.includes('A')) return `${data.rating}`;
  if (data.rating.includes('B')) return `${data.rating}`;
  if (data.rating.includes('C')) return `${data.rating}`;
  if (data.rating.includes('D')) return `${data.rating}`;
};

export const RadialChart: React.FC<Props> = props => {

  const {
    chartSize,
    data
  } = props;

  return (
    <ReactSpeedometer
      {...chartSize}
      currentValueText={calculateQuality(data) || 'N/A'}
      minValue={10}
      maxValue={100}
      customSegmentStops={customSegmentStops}
      segmentColors={segmentColors}
      customSegmentLabels={customSegmentLabels}
      value={data.score}
    />);
};

// Types
type Props = {
  chartSize: {
    width: number
    height: number
    ringWidth: number
    needleHeightRatio: number
    valueTextFontSize: string,
  }
  data: RadialRatingData
}
