import React from 'react';
import {TypographyStyles} from '../../styles/common/typography';
import styled from 'styled-components/macro';

export const ModalContentPrivacyPolicy: React.FC = () => {

  // Styles
  const TS = TypographyStyles;

  return (<>
    <TitleStyled color="primary" size="xxxl" marginBottom="xl">Privacy Policy</TitleStyled>

    <Content>
      <TS.Title marginBottom="xs">What information do we collect?</TS.Title>
      <TS.Paragraph>
        Denominator is dedicated to protecting your privacy. This Privacy Policy explains how Denominator
        collects and
        uses your personal data. It also describes your rights towards us and how to exercise them. You may always reach
        out to us on matters of privacy and data protection by sending an email to <a
        href="mailto:privacy@denominator.com">privacy@denominator.com</a>.
      </TS.Paragraph>

      <TS.Paragraph>
        In order to provide our services, we need to process your personal data. We do this with the utmost
        consideration.
      </TS.Paragraph>

      <TS.Title marginBottom="xs">What do we do with your information?</TS.Title>
      <TS.Paragraph>
        Provide, operate and improve our services. All data is used to provide, operate and improve Denominator’s
        services and the information you give us, or we collect, is generally required to improve the user experience or
        to fulfill the service that we provide.
      </TS.Paragraph>

      <TS.Title marginBottom="xs">Information you give us or we collect about you</TS.Title>
      <TS.Paragraph>
        You may directly or indirectly give us information about yourself in a variety of ways, such as when you contact
        us via our website, apply for a job, etc. Denominator processes personal data for the following purposes and
        based on the following legal bases:
      </TS.Paragraph>

      <TS.Title size="m" marginBottom="s">Website visitor & prospective customer</TS.Title>
      <TS.Paragraph>
        When you visit our website, we may collect the following information in order to provide the requested Service,
        generate website statistics and analytics, improve the customer experience on the website and target the right
        audience with our advertising:
      </TS.Paragraph>

      <TS.Paragraph>
        <List>
          <li>Personal and contact information – name, company email and phone number.</li>
          <li>Traffic information – as provided by your web browser such as browser type, language and the address of
            the
            website from which you arrived and other traffic information such as IP address
          </li>
          <li>Clickstream behavior – such as which links you click and when</li>
          <li>Other personal data – data contained in your profile on third party social networks (Facebook, LinkedIn,
            etc.)
          </li>
        </List>
      </TS.Paragraph>

      <TS.Title marginBottom="xs">Communicate with you</TS.Title>
      <TS.Paragraph>
        Denominator may also use your data to communicate relevant information regarding used or similar services. If
        you do not wish to receive such communication, please send an email to <a
        href="mailto:privacy@denominator.com">privacy@denominator.com</a>.
      </TS.Paragraph>

      <TS.Title marginBottom="xs">What we DO NOT do with your data</TS.Title>
      <TS.Paragraph>We will not sell your personal details to third parties.</TS.Paragraph>

      <TS.Title marginBottom="xs">Where do we store your personal data?</TS.Title>
      <TS.Paragraph>
        The data is processed in a secure environment in the US and can in certain cases be processed in other regions
        by a supplier or subcontractor. As Denominator is committed to always protecting your data, Denominator will
        take all reasonable legal, technical, and organizational measures to ensure that your data is treated securely
        and with an adequate level of protection.
      </TS.Paragraph>

      <TS.Title marginBottom="xs">How long do we keep your personal data?</TS.Title>
      <TS.Paragraph>
        We keep your data only as long as necessary to fulfil our obligations towards you and as required by statutory
        retention periods. Where we keep your data for purposes beyond those of our obligations, we keep the data only
        as long as necessary for the respective purpose.
      </TS.Paragraph>

      <TS.Title marginBottom="xs">Your rights to access, rectification and deletion</TS.Title>
      <TS.Paragraph>
        <List>
          <li>Right to access your data. You may request a transcript of records if you would like to know and verify
            the
            information we have on you. The required information will be delivered within 45 days of receiving the
            verifiable request.
          </li>
          <li>Right to rectification. You have the right to correct inaccurate or incomplete information about yourself.
          </li>
          <li>Right to deletion (“right to be forgotten”). You have the right to request the deletion of your personal
            data
            insofar as this personal data is no longer necessary for the purpose it was collected. For Californian
            citizens
            the information will be deleted upon the request.
          </li>
          <li>Contact. For issues relating to your rights to access, rectification and deletion please contact <a
            href="mailto:privacy@denominator.com">privacy@denominator.com</a>.
          </li>
        </List>
      </TS.Paragraph>

      <TS.Title marginBottom="xs">What about cookies and similar tracking technologies?</TS.Title>
      <TS.Paragraph>
        We use cookies and similar technologies to deliver a tailored and smooth online experience. For detailed
        information about how Denominator uses cookies and similar, please read our cookie statement.
      </TS.Paragraph>

      <TS.Title marginBottom="xs">Cookie statement</TS.Title>
      <TS.Paragraph>
        Please reference Denominators website cookie statement.
      </TS.Paragraph>

      <TS.Title marginBottom="xs">Contact us</TS.Title>
      <TS.Paragraph>
        Denominator is a company incorporated and registered in Delware, USA. You can always reach Denominator’s Data
        Privacy teams at: <a href="mailto:privacy@denominator.com">privacy@denominator.com</a>. Denominator is the
        responsible entity (controller) for the processing
        of your personal data as a website visitor, a prospective customer or a job candidate.
      </TS.Paragraph>
    </Content>
  </>);
};

// Styles
const Content = styled.div`
  overflow-x: auto;

  padding: 0 1rem;
`;

const TitleStyled = styled(TypographyStyles.Title)`
  text-align: center;
`;

const List = styled.ul`
  list-style: square outside;
  padding-left: 5rem;

  & li:not(:last-child) {
    padding-bottom: .5rem;
  }
`;