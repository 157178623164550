import React from 'react';
import {LayoutStyles} from '../../../../core/styles/common/layout';
import {TypographyStyles} from '../../../../core/styles/common/typography';
import {RadialRating} from '../../../../core/components/ratings/RadialRating/RadialRating';
import styled from 'styled-components/macro';

export const RadialRatingCard: React.FC<Props> = props => {

  const {
    title,
    data
  } = props;

  const TS = TypographyStyles;
  const LS = LayoutStyles;

  return (
    <LS.Card>
      <TitleWrapper>
        <TS.TitleM>{title}</TS.TitleM>
      </TitleWrapper>
      <RadialRating data={data}/>
    </LS.Card>
  );
};

// Styles
const TitleWrapper = styled.div`
  margin-bottom: 1.4rem;
`;

// Types
type Props = {
  title: string
  data: any
}