import styled from 'styled-components/macro';
import {TypographyStyles} from '../../styles/common/typography';
import {ReactComponent as MainSVG} from '../../assets/svg/mail.svg';
import React from 'react';

export const ModalContentContactUs: React.FC = () => {

  // Styles
  const TS = TypographyStyles;

  return (<>
    <TS.TextWrapper>
      <TS.TitleM>
        Please contact us!
      </TS.TitleM>
    </TS.TextWrapper>
    <MailCard>
      <MailIcon/>
      <MailLink href="mailto:fqequityindex@thefq.com">fqequityindex@thefq.com</MailLink>
    </MailCard>
  </>);
};

// Styles
const MailCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 1rem 1.5rem;

  border-radius: ${({theme}) => theme.shape.borderRadius + 'px'};
  background-color: ${({theme}) => theme.palette.grey[200]};
`;

const MailIcon = styled(MainSVG)`
  margin-right: .6rem;
  width: 2rem;

  fill: ${({theme}) => theme.palette.grey[600]};
`;

const MailLink = styled.a`
  font-size: 1.2rem;
  color: ${({theme}) => theme.palette.primary.main};
`;

// Props
type Props = {
  title: string
}