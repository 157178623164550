import React from 'react'
import {RatingDot, RatingValue} from './RatingDot'
import styled from 'styled-components/macro'

export const SingleRating: React.FC<Props> = props => {

    const {
        title,
        display,
        scoreValue,
        ratingValue
    } = props

    return (
        <MainContainer>
            <Title>{title}</Title>
            <RatingWrapper>
                {ratingValue && (
                    <RatingDotStyled ratingValue={ratingValue}/>
                )}
                <Rating>
                    {display === 'score' && scoreValue}
                    {display === 'rating' && ratingValue}
                </Rating>
            </RatingWrapper>
        </MainContainer>
    )
}

// Styles
const MainContainer = styled.div`
  display: block;
`

const Title = styled.span`
  
  font-size: .8rem;
  color: ${({theme}) => theme.palette.grey[500]};
`

const RatingWrapper = styled.div`
  display: flex;
  align-items: center;
`

const RatingDotStyled = styled(RatingDot)`
  margin-right: .5rem;
`

const Rating = styled.span`
  font-size: 1.2rem;
`

// Types
type Props = {
    title: string,
    display: 'score' | 'rating'
    scoreValue?: number
    ratingValue?: RatingValue
}