import {LocalStorageVar} from '../localStorage';

export enum RequestStatus {
  still,
  loading,
  fail
}

export enum APIUrl {
  // Authentication
  login = 'fqLogin',
  register = 'fqRegister',
  dictionaries = 'fqDictionaries',

  // Main page lookup search
  industriesLookup = 'fqIndustriesLookup',
  companiesLookup = 'fqCompaniesLookup',

  // Database results
  industry = 'fqIndustries',
  company = 'fqCompany',
}

// dev = https://denominator-api-external-dev.0rsk44vv9evf0.us-east-2.cs.amazonlightsail.com 
// prod = https://qcdfgjhyjh.execute-api.us-east-2.amazonaws.com/Prod'
const host = process.env.REACT_APP_HOST || 'https://denominator-api-external-dev.0rsk44vv9evf0.us-east-2.cs.amazonlightsail.com';

// TODO: Type the class properly.
class ResponseError extends Error {
  constructor(message: any, severity: ErrorSeverity, incidentId?: string, ...params: any) {
    super(...params);
    // @ts-ignore
    this.severity = severity;
    // @ts-ignore
    this.message = message;
    // @ts-ignore
    this.incidentId = incidentId
  }
}

const handleResponse = (parsed: any) => {
  const {severity, incidentId} = parsed;

  if (severity as ErrorSeverity) {
    if (severity === 'Information') throw new ResponseError(parsed.message, severity);
    if (severity === 'Warning') throw new ResponseError(parsed.message, severity);
    if (severity === 'Error') throw new ResponseError(parsed.message, severity);
    if (severity === 'AccessDenied') throw new ResponseError(parsed.message, severity);
    if (severity === 'Critical') throw new ResponseError(parsed.message, severity, incidentId);
  }
};

const getHeaders = () => {
  const user = localStorage.getItem(LocalStorageVar.userData);
  const freeToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiJ0ZXN0VXNlciIsIm5iZiI6MTYzMDQxMDI1OSwiZXhwIjoxOTQ1OTQzMDU5LCJpYXQiOjE2MzA0MTAyNTl9.jgxQ7hPaijT51RzfzZFV6wXINibFzUvkRBrizBD8ppg';
  const token = user ? JSON.parse(user).token : freeToken;

  return {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Request-Headers': '*',
    'x-api-token': token,
  };
};

const post = <T extends {}>(url: string, data: T) => fetch(`${host}/${url}`, {
  method: 'POST',
  mode: 'cors',
  headers: getHeaders(),
  referrerPolicy: 'no-referrer',
  body: JSON.stringify(data),
});

export const get = (url: string, params?: string) =>
  fetch(`${host}/${url}${params ? '?' + params : ''}`, {
    method: 'GET',
    headers: getHeaders(),
    referrerPolicy: 'no-referrer',
  });

export async function getRequest(url: string, params?: string) {

  const response = await get(url, params);
  const parsed = await response.json();

  handleResponse(parsed);

  return parsed;
}

// Backend expects some POST queries to have both POST and GET style parameters
export async function postRequest(url: string, data: any, params?: string) {

  const fullUrl = url + (params ? '?' + params : '');
  const response = await post(fullUrl, data);
  const parsed = await response.json();

  handleResponse(parsed);

  return parsed;
}

// Types
export type SignInBody = {
  username: string
  password: string
}

export type SignInResponse = {
  token: string
  accessRights: Array<string>
}

export type ErrorSeverity = 'Information'
  | 'Warning'
  | 'Error'
  | 'AccessDenied'
  | 'Critical'