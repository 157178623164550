import React, {useState} from 'react';
import {Input} from '../../ui-elements/inputs/Input';
import {Field, FieldProps} from 'formik';
import styled from 'styled-components/macro';
import {ReactComponent as HideSVG} from '../../../assets/svg/hidden/hide.svg';
import {ReactComponent as ShowSVG} from '../../../assets/svg/hidden/show.svg';

export const PasswordField: React.FC<Props> = props => {

  // TODO [Mark]: Improve clicking on hide/show icon. Prevent bubbling and capturing.

  const {
    label,
    name,
    validate,
  } = props;

  const [hidden, setHidden] = useState<boolean>(true);

  return (
    <Field name={name}>
      {({field, form, meta}: FieldProps) => (
        <FieldInner>
          <Label>{label}</Label>
          <InputWrapper>
            <Input
              type={hidden ? 'password' : 'text'}
              {...field}
            />
            {hidden && <ShowSVG onClick={() => setHidden(false)}/>}
            {!hidden && <HideSVG onClick={() => setHidden(true)}/>}
          </InputWrapper>
          <ErrorBox>
            <span>{meta.error}</span>
          </ErrorBox>
        </FieldInner>
      )}
    </Field>
  );
};

// Styles
const FieldInner = styled.div`
  position: relative;

  margin-bottom: 2rem;
`;

const Label = styled.div`
  margin-bottom: .5rem;
  padding-left: .5rem;
  color: ${({theme}) => theme.palette.custom.secondaryGrey}
`;

const InputWrapper = styled.div`
  position: relative;

  & svg {
    position: absolute;
    top: calc(50% - 12px);
    right: 1rem;
    z-index: 2;

    fill: ${({theme}) => theme.palette.grey[600]};
    cursor: pointer;

    &:hover {
      fill: ${({theme}) => theme.palette.custom.secondaryAccent};
    }
  }
`;

const ErrorBox = styled.div`
  position: absolute;
  padding-left: .5rem;

  & > span {
    font-size: .8rem;
    color: red;
  }
`;

// Types
type Props = {
  label: string
  name: string
  validate?: any
}