import React from 'react'
import {RadialChart} from '../../charts/RadialChart'
import {SingleRating} from '../components/SingleRating'
import {RatingValue} from '../components/RatingDot'
import styled from 'styled-components/macro'

const mediumSizeChart = {
    width: 200,
    height: 150,
    ringWidth: 30,
    needleHeightRatio: .4,
    valueTextFontSize: '13',
}

export const RadialRating: React.FC<Props> = props => {

    const {
        chartSize = 'medium', // TODO: Write logic for choosing chart size.
        data,
    } = props

    return (
        <MainWrapper>
            <RadialChart chartSize={mediumSizeChart} data={data}/>
            <RatingInfoWrapper>
                <SingleRating title="Rating" ratingValue={data.rating} display='rating'/>
                <SingleRating title="Score" scoreValue={data.score} display='score'/>
                <SingleRating title="Strength score" scoreValue={data.strength} display='score'/>
            </RatingInfoWrapper>
        </MainWrapper>
    )
}

// Styles
const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const RatingInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  
  width: 100%;
`

// Types
type Props = {
    chartSize?: 'small' | 'medium' | 'large'
    data: RadialRatingData
}

export type RadialRatingData = {
    quality?: string // NOTE: Quality is missing in country data.
    rating: RatingValue,
    score: number
    strength: number
}