import {RegistrationValues, SignInValues, UserData} from './authTypes';
import {SearchTab} from '../../mainSearch/redux/mainSearchTypes';

export enum AuthActionType {
  // Sign, logout.
  SIGN_IN = 'auth/SIGN_IN',
  SIGN_IN_SUCCESS = 'auth/SUCCESS',
  SIGN_IN_FAIL = 'auth/FAIL',
  LOGOUT = 'auth/LOGOUT',
  // Register
  REGISTER = 'auth/REGISTER',
  REGISTER_SUCCESS = 'auth/REGISTER_SUCCESS',
  REGISTER_FAIL = 'auth/REGISTER_FAIL',
  SET_REGISTRATION_STEP = 'auth/SET_REGISTRATION_STEP',
  REGISTRATION_VALUES_SET = 'auth/REGISTRATION_VALUES_SET',
}

export const authAction = {
  singIn: (signInValues: SignInValues) => ({
    type: AuthActionType.SIGN_IN as const,
    payload: {
      signInValues
    }
  }),
  signInSuccess: (userData: UserData, currentTab: SearchTab) => ({
    type: AuthActionType.SIGN_IN_SUCCESS as const,
    payload: {
      userData,
      currentTab
    }
  }),
  signInFail: () => ({
    type: AuthActionType.SIGN_IN_FAIL as const
  }),
  logout: () => ({
    type: AuthActionType.LOGOUT as const
  }),
  register: (formValues: RegistrationValues) => ({
    type: AuthActionType.REGISTER as const,
    payload: {
      formValues
    }
  }),
  registerSuccess: () => ({
    type: AuthActionType.REGISTER_SUCCESS as const
  }),
  registerFailed: () => ({
    type: AuthActionType.REGISTER_FAIL as const
  }),
  setRegistrationStep: (registrationStep: number) => ({
    type: AuthActionType.SET_REGISTRATION_STEP as const,
    payload: {
      registrationStep
    }
  }),
  registrationValuesSet: (values: RegistrationValues) => ({
    type: AuthActionType.REGISTRATION_VALUES_SET as const,
    payload: {
      values
    }
  }),
};

export type AuthAction = ReturnType<typeof authAction.singIn>
  | ReturnType<typeof authAction.signInSuccess>
  | ReturnType<typeof authAction.signInFail>
  | ReturnType<typeof authAction.logout>
  | ReturnType<typeof authAction.register>
  | ReturnType<typeof authAction.registerSuccess>
  | ReturnType<typeof authAction.registerFailed>
  | ReturnType<typeof authAction.setRegistrationStep>
  | ReturnType<typeof authAction.registrationValuesSet>