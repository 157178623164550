import React, {useEffect, useRef, useState} from 'react';
import {CircularProgress, ListItem, ListItemText, TextField} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import {MainSearchState} from '../redux/mainSearchDuck';
import {FixedSizeList, ListChildComponentProps} from 'react-window';
import {usePrevious} from '../../../helpers/helpers';
import {useHistory} from 'react-router-dom';
import {useOnclickAwayHandler} from '../../../hooks/useOnclickAwayHandler';
import styled from 'styled-components/macro';
import {AppState} from '../../app/redux/appDuck';
import {APIUrl, RequestStatus} from '../../../core/api/api';
import {ButtonPrimaryOutlined} from '../../../core/components/ui-elements/buttons';
import {RootState} from '../../../core/redux/store';
import {AuthState} from '../../auth/redux/authDuck';
import {ModalContentContactUs} from '../../../core/components/modalContents/ModalContentContactUs';
import {Modal} from '../../../core/components/modal/Modal';
import {mainSearchAction} from '../redux/mainSearchAction';
import {SearchTab} from '../redux/mainSearchTypes';
import {Input} from '../../../core/components/ui-elements/inputs/Input';

export const SearchBar: React.FC = () => {


  // Selectors
  const {status} = useSelector<RootState, AppState>(state => state.app);
  const {userData} = useSelector<RootState, AuthState>(state => state.auth);
  const {search, searchResult, activeTab} = useSelector<RootState, MainSearchState>(state => state.mainSearch);

  // Actions
  const dispatch = useDispatch();
  const saveSearch = (search: string) => dispatch(mainSearchAction.lookupSave(search));
  const searchRequest = (url: APIUrl, params: string) => dispatch(mainSearchAction.lookupLoad(url, params));
  const clearSearchResults = () => dispatch(mainSearchAction.lookupClearResults());
  const lookupClearAll = () => dispatch(mainSearchAction.lookupClearAll());
  const setTab = (activeTab: SearchTab) => dispatch(mainSearchAction.tabSet(activeTab));

  // History API
  const prevSearch = usePrevious(search);
  const history = useHistory();
  const prevTab = usePrevious(activeTab);

  const tabs: Array<SearchTab> = userData ? ['industry', 'company'] : ['industry'];

  // Helper functions
  const getUrl = (tab: SearchTab) => {
    if (tab === 'company') return APIUrl.companiesLookup;
    if (tab === 'industry') return APIUrl.industriesLookup;
  };

  function renderSearchResults(props: ListChildComponentProps) {
    const {index, style} = props;
    const resultRow = (
      <Result
        onClick={() => history.push(`/database/${searchResult[index].id}`)}
      >
        <ResultName>{searchResult[index].name}</ResultName>
      </Result>
    );

    return (
      <ListItemStyled style={style} key={index}>
        <ListItemText primary={resultRow}/>
      </ListItemStyled>
    );
  }

  const searchZone = useRef(null);
  useOnclickAwayHandler(searchZone, lookupClearAll);

  // Local state
  const [showModal, setShowModal] = useState<boolean>(false);
  const toggleModal = () => setShowModal(!showModal);

  useEffect(() => {
    const searchChange = search !== prevSearch;

    const tabChanged = activeTab !== prevTab;
    const searchPrefix = `search_prefix=${encodeURIComponent(search)}`;
    const pageNumberParam = `page_number=${1}`;
    const pageSizeParam = `page_size=${10}`;
    const params = `${searchPrefix}&${pageNumberParam}&${pageSizeParam}`;

    if (tabChanged) {
      lookupClearAll();
    }

    if (searchChange) {

      if (!search) clearSearchResults();

      if (prevSearch !== undefined) {
        if (searchChange && search.trim() !== '' && search) {
          searchRequest(getUrl(activeTab) as APIUrl, params);
          clearSearchResults();
        }

        if (search.trim() !== '' && search) {
          searchRequest(getUrl(activeTab) as APIUrl, params);
        }
      }
    }
  }, [search, searchResult, activeTab]);

  return (<>
    <Modal
      toggleModal={toggleModal}
      content={<ModalContentContactUs/>}
      show={showModal}
      width={40}
      closeOnBackdrop={false}
    />
    <Container>
      {/* Tabs: Company/Industry/Country */}
      <TabWrapper>
        {tabs.map(tab => (
          <Tab
            key={tab}
            onClick={() => setTab(tab)}
            selected={activeTab === tab}
          >
            {tab} database
          </Tab>
        ))}
      </TabWrapper>

      <SearchZone ref={searchZone}>
        {/* Search bar */}
        <InputContainer>
          <TextFieldStyled
            type="text"
            name="search"
            placeholder={'Search...'}
            autoComplete="off"
            value={search}
            onChange={(e) => saveSearch(e.target.value)}
          />

          {/* Show all results button */}
          {activeTab === 'company' && (
            <ButtonPrimaryOutlinedStyled
              disabled={status === RequestStatus.loading}
              type={'button'}
              onClick={toggleModal}
            >
              {status === RequestStatus.loading && !searchResult.length
                ?
                <CircularProgress size={30}/>
                :
                'Can’t find your company? Click here'
              }
            </ButtonPrimaryOutlinedStyled>
          )}
        </InputContainer>

        {/* Search results */}
        {searchResult.length > 0
          ?
          <SearchResults key={searchResult.length}>
            <FixedSizeList
              itemData={searchResult}
              height={300}
              width={'100%'}
              itemSize={30}
              itemCount={searchResult.length}
            >
              {renderSearchResults}
            </FixedSizeList>
          </SearchResults>
          :
          null
        }
      </SearchZone>
    </Container>
  </>);
};

// Styles
const Result = styled.div`
  font-size: .9rem;

  cursor: pointer;
`;

const ResultName = styled.span`
  font-weight: bold;
`;

const ListItemStyled = styled(ListItem)<any>`

  &:hover {
    background-color: ${({theme}) => theme.palette.custom.primaryPale};
  }
`;

const Container = styled.div`
  position: relative;
  padding: 2rem 4rem;
`;

const TabWrapper = styled.div`
  display: flex;

  & div {
    margin-bottom: -1rem;

    &:not(:last-child) {
      margin-right: .2rem;
    }
  }
`;

const Tab = styled.div<{ selected: boolean }>`
  padding: 1rem 1.5rem 1.8rem;

  font-size: .9rem;
  font-family: ${({theme}) => theme.typography.fontFamilyAlt};
  font-weight: 600;
  text-transform: uppercase;
  color: ${({selected, theme}) => selected ? theme.palette.common.white : theme.palette.primary.main};

  background-color: ${({selected, theme}) => selected ? theme.palette.primary.main : theme.palette.common.white};
  border-radius: 5px;

  &:hover {
    cursor: pointer;
    background-color: ${({theme}) => theme.palette.primary.main};
    color: ${({theme}) => theme.palette.common.white};
  }
`;

const SearchZone = styled.div`
  position: relative;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: stretch;
`;

const TextFieldStyled = styled(Input)`
  padding-left: 1.5rem;
  
  height: 100%;
`;

const ButtonPrimaryOutlinedStyled = styled(ButtonPrimaryOutlined)`
  position: relative;
  right: 1.4rem;
  z-index: 2;
  
  width: 18rem;
`;

const SearchResults = styled.div`
  position: absolute;
  z-index: 1000;

  padding-bottom: 1.2rem;

  height: fit-content;
  width: 100%;

  background-color: ${({theme}) => theme.palette.common.white};

  box-shadow: ${({theme}) => theme.shadows[1]};
`;