import React from 'react';
import {Input} from '../../ui-elements/inputs/Input';
import {Field, FieldProps} from 'formik';
import styled from 'styled-components/macro';

export const TextField: React.FC<Props> = props => {

  const {
    label,
    name,
    validate,
  } = props;

  return (
    <Field name={name}>
      {({field, form, meta}: FieldProps) => (
        <FieldInner>
          <Label>{label}</Label>
          <Input
            type="text"
            {...field}
          />
          <ErrorBox>
            <span>{meta.error}</span>
          </ErrorBox>
        </FieldInner>
      )}
    </Field>
  );
};

// Styles
const FieldInner = styled.div`
  position: relative;
  
  margin-bottom: 2rem;
`;

const Label = styled.div`
  margin-bottom: .5rem;
  padding-left: .5rem;
  color: ${({theme}) => theme.palette.custom.secondaryGrey}
`;

const ErrorBox = styled.div`
  position: absolute;
  padding-left: .5rem;

  & > span {
    font-size: .8rem;
    color: red;
  }
`;

// Types
type Props = {
  label: string
  name: string
  validate?: any
}