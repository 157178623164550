import {call, delay, put, takeLatest} from 'redux-saga/effects';
import {getRequest, RequestStatus} from '../../../core/api/api';
import {LocalStorageVar} from '../../../core/localStorage';
import {MainSearchAction, mainSearchAction, MainSearchActionType} from './mainSearchAction';
import {SearchTab} from './mainSearchTypes';
import {appAction} from '../../app/redux/appAction';
import {standardError} from '../../../helpers/sagaHelpers';

// Workers
export function* MainSearchWorker(action: ReturnType<typeof mainSearchAction.lookupLoad>): any {
  const {url, params} = action.payload;

  yield put(appAction.load());
  yield delay(500);

  try {
    const res = yield call(getRequest, url, params);
    yield put(mainSearchAction.lookupSuccess(url, res));
    yield put(appAction.success());

  } catch (error) {
    yield standardError(error, mainSearchAction.lookupFail());
  }
}

// Watchers
export function* mainSearchWatcher() {
  yield takeLatest(MainSearchActionType.LOOKUP_LOAD, MainSearchWorker);
}

// Reducer
const tabStorage = localStorage.getItem(LocalStorageVar.mainSearchCurrentTab);
const searchResultStorage = localStorage.getItem(LocalStorageVar.searchResult);
const selectedIndustry = localStorage.getItem(LocalStorageVar.selectedIndustry)

export const initialState: MainSearchState = {
  activeTab: tabStorage ? tabStorage as SearchTab : 'industry',
  search: '',
  searchResult: searchResultStorage ? JSON.parse(searchResultStorage) : [],
  selectedIndustry: selectedIndustry ? JSON.parse(selectedIndustry) : null,
  status: RequestStatus.still,
};

export function mainSearchReducer(state: MainSearchState = initialState, action: MainSearchAction): MainSearchState {
  // const {type, payload} = action;

  switch (action.type) {
    case MainSearchActionType.TAB_SET:
      const {activeTab} = action.payload;
      localStorage.setItem(LocalStorageVar.mainSearchCurrentTab, activeTab);

      return {
        ...state,
        activeTab: activeTab
      };


    case MainSearchActionType.LOOKUP_LOAD: {
      return {
        ...state,
        status: RequestStatus.loading
      }
    }

    case MainSearchActionType.LOOKUP_SUCCESS: {
      const {url, searchResult} = action.payload;
      if (url === 'fqIndustriesLookup') {
        localStorage.setItem(LocalStorageVar.searchResult, JSON.stringify(searchResult.industries));

        return {
          ...state,
          searchResult: [...state.searchResult, ...searchResult.industries],
        };
      }

      if (url === 'fqCompaniesLookup') {
        localStorage.setItem(LocalStorageVar.searchResult, JSON.stringify(searchResult.companies));

        return {
          ...state,
          searchResult: [...state.searchResult, ...searchResult.companies],
        };
      }

      return state;
    }

    case MainSearchActionType.LOOKUP_FAIL: {
      return {
        ...state,
        status: RequestStatus.fail
      }
    }

    case MainSearchActionType.LOOKUP_SAVE: {
      const {search} = action.payload;

      return {
        ...state,
        search: search,
      };
    }


    case MainSearchActionType.LOOKUP_CLEAR_RESULTS:
      return {
        ...state,
        searchResult: [],
      };

    case MainSearchActionType.LOOKUP_CLEAR_ALL:
      return {
        ...state,
        search: '',
        searchResult: [],
      };

    case MainSearchActionType.SELECT_INDUSTRY:
      const {industryId} = action.payload
      localStorage.setItem(LocalStorageVar.selectedIndustry, JSON.stringify(industryId));

      return {
        ...state,
        selectedIndustry: industryId === state.selectedIndustry ? null : industryId
      };

    default:
      return state;
  }
}

// Types
export type MainSearchState = {
  activeTab: SearchTab
  search: string,
  searchResult: Array<any>
  selectedIndustry: number | null
  status: RequestStatus
}

