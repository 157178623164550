import React, {ReactElement} from 'react';
import {Field, FieldProps} from 'formik';
import styled from 'styled-components/macro';
import {Checkbox} from '../../ui-elements/inputs/Checkbox';
import {FormStyles} from '../../../styles/common/form';

export const CheckboxField: React.FC<Props> = props => {

  const {
    label,
    name,
    validate,
    text
  } = props;

  // Styles
  const FS = FormStyles

  return (
    <Field name={name} validate={validate}>
      {({field, meta}: FieldProps) => (
        <FieldInner>
          {label && <FS.Label>{label}</FS.Label>}
          <CheckboxWrapper>
            <Checkbox
              checked={field.value}
              {...field}
            />
            {text && <Text>{text}</Text>}
          </CheckboxWrapper>
          <ErrorBox>
            <span>{meta.error}</span>
          </ErrorBox>
        </FieldInner>
      )}
    </Field>
  );
};

// Styles
const FieldInner = styled.div`
  position: relative;

  margin-bottom: 2rem;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: start;
`

const Text = styled.div`
  position: relative;
  top: -0.3rem;
`

const ErrorBox = styled.div`
  position: absolute;
  padding-left: .5rem;

  & > span {
    font-size: .8rem;
    color: red;
  }
`;

// Types
type Props = {
  label?: string
  name: string
  validate?: any
  text?: string | ReactElement
}