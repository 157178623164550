import {DatabaseRequest} from './databaseTypes';

export enum DatabaseActionType {
  DATABASE_LOAD = 'database/DATABASE_LOAD',
  DATABASE_SUCCESS = 'database/DATABASE_SUCCESS',
  DATABASE_FAIL = 'database/DATABASE_FAIL',
  DATABASE_CLEAR = 'database/DATABASE_CLEAR'
}

export const databaseAction = {
  databaseLoad: (databaseRequest: DatabaseRequest) => ({
    type: DatabaseActionType.DATABASE_LOAD as const,
    payload: {
      databaseRequest
    }
  }),
  databaseSuccess: (databaseData: any) => ({
    type: DatabaseActionType.DATABASE_SUCCESS as const,
    payload: {
      databaseData
    }
  }),
  databaseFail: () => ({
    type: DatabaseActionType.DATABASE_FAIL as const,
  }),
  databaseClear: () => ({
    type: DatabaseActionType.DATABASE_CLEAR as const
  })
};

export type DatabaseAction =
  ReturnType<typeof databaseAction.databaseLoad>
  | ReturnType<typeof databaseAction.databaseSuccess>
  | ReturnType<typeof databaseAction.databaseFail>
  | ReturnType<typeof databaseAction.databaseClear>