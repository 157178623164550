import {createStore, applyMiddleware, combineReducers} from 'redux';
import createSagaMiddleware from 'redux-saga'
import {composeWithDevTools} from 'redux-devtools-extension'

import {fork} from 'redux-saga/effects';
import {appReducer, appWatcher} from '../../features/app/redux/appDuck';
import {mainSearchReducer, mainSearchWatcher} from '../../features/mainSearch/redux/mainSearchDuck';
import {databaseReducer, databaseWatcher} from '../../features/database/redux/databaseDuck';
import {authReducer, authWatcher} from '../../features/auth/redux/authDuck';

// Reducer
const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  mainSearch: mainSearchReducer,
  database: databaseReducer
});

// Saga
export default function* rootSaga() {
  yield fork(appWatcher);
  yield fork(authWatcher);
  yield fork(mainSearchWatcher);
  yield fork(databaseWatcher);
}

// Store
const sagaMiddleware = createSagaMiddleware()

export const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(sagaMiddleware)),
)

sagaMiddleware.run(rootSaga) // Runs ALL the sagas... Like combineReducers.

// Types
export type RootState = ReturnType<typeof rootReducer>