import React from 'react';
import styled from 'styled-components/macro';
import {HeaderProfile} from './components/HeaderProfile';
import {LogoHeader} from '../../ui-elements/logos/LogoHeader';

export const Header: React.FC = () => {

  return (
    <HeaderMain>
      <HeaderLeft>
        <LogoHeader/>
      </HeaderLeft>
      <HeaderProfile/>
    </HeaderMain>
  );
};

// Styles
const HeaderMain = styled.header`
  display: flex;
  justify-content: space-between;
  padding: .8rem 2rem;

  min-height: 8vh;

  background-color: ${({theme}) => theme.palette.common.white};
  box-shadow: ${({theme}) => theme.shadows[2]};
`;

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;

  & > div:not(:last-child) {
    margin-right: 2rem;
  }
`;
