import {OptionsGeneratorVisible} from '../../../../core/components/ui-elements/optionLIst/OptionsGeneratorVisible';
import React, {useCallback} from 'react';
import styled from 'styled-components/macro';
import {ButtonPrimaryOutlined} from '../../../../core/components/ui-elements/buttons';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../../../core/redux/store';
import {MainSearchState} from '../../redux/mainSearchDuck';
import {mainSearchAction} from '../../redux/mainSearchAction';
import {AppState} from '../../../app/redux/appDuck';
import {DatabaseRequest} from '../../../database/redux/databaseTypes';
import {databaseAction} from '../../../database/redux/databaseAction';

export const IndustryOptions: React.FC<Props> = props => {

  const {
    showResult
  } = props;

  // Selectors
  const {dictionaries} = useSelector<RootState, AppState>(state => state.app);
  const {selectedIndustry} = useSelector<RootState, MainSearchState>(state => state.mainSearch);

  // Actions
  const dispatch = useDispatch();
  const selectIndustry = (industryId: number) => dispatch(mainSearchAction.selectIndustry(industryId));
  const databaseLoad = (databaseRequest: DatabaseRequest) => dispatch(databaseAction.databaseLoad(databaseRequest));

  // Functions
  const viewResultsDisabled = () => !selectedIndustry;

  const handleClick = (optionsId: number) => {
    selectIndustry(optionsId);
  };

  const handleChecked = useCallback((id: number) => {
    return selectedIndustry === id;
  }, [selectedIndustry]);

  return (<>
    {dictionaries &&
    <ColumnsWrapper>
      {/* First column */}
      <Column>
        {dictionaries.industries && dictionaries.industries.map((item, index) => {
          if (index < 3) {
            return (
              <OptionsGroup>
                <OptionsGeneratorVisible
                  key={item.id}
                  option={item}
                  handleChecked={handleChecked}
                  handleClick={handleClick}
                />
              </OptionsGroup>
            );
          }
        })}
      </Column>

      {/* Second column */}
      <Column>
        {dictionaries.industries && dictionaries.industries.map((item, index) => {
          if (index > 2 && index < 7) {
            return (
              <OptionsGroup>
                <OptionsGeneratorVisible
                  key={item.id}
                  option={item}
                  handleChecked={handleChecked}
                  handleClick={handleClick}
                />
              </OptionsGroup>
            );
          }
        })}
      </Column>

      {/* Third column */}
      <Column>
        {dictionaries.industries && dictionaries.industries.map((item, index) => {
          if (index > 6 && index < 11) {
            return (
              <OptionsGroup>
                <OptionsGeneratorVisible
                  key={item.id}
                  option={item}
                  handleChecked={handleChecked}
                  handleClick={handleClick}
                />
              </OptionsGroup>
            );
          }
        })}
      </Column>

      {/* Fourth column */}
      <Column>
        {dictionaries.industries && dictionaries.industries.map((item, index) => {
          if (index > 10) {
            return (
              <OptionsGroup>
                <OptionsGeneratorVisible
                  key={item.id}
                  option={item}
                  handleChecked={handleChecked}
                  handleClick={handleClick}
                />
              </OptionsGroup>
            );
          }
        })}
      </Column>

      <Column>
        {/* View results button */}
        <ViewResultsButtonWrapper>
          <ButtonPrimaryOutlinedStyled
            variant="contained"
            disabled={viewResultsDisabled()}
            onClick={showResult}>
            View results
          </ButtonPrimaryOutlinedStyled>
        </ViewResultsButtonWrapper>
      </Column>
    </ColumnsWrapper>}
  </>);
};

const ColumnsWrapper = styled.div`
  display: flex;
  justify-content: stretch;
  flex-wrap: wrap-reverse;

  & > div {
    border-right: ${({theme}) => `1.5px solid ${theme.palette.custom.border}`};
    border-left: ${({theme}) => `1.5px solid ${theme.palette.custom.border}`};
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 20%;

  padding: 1rem .5rem 0 .5rem;

  font-size: .9rem;
`;

const OptionsGroup = styled.div`
  & > div {
    margin-bottom: .1rem;
  }

  margin-bottom: .6rem;
`;

const ViewResultsButtonWrapper = styled.div`
  display: flex;
  justify-content: center;

  padding: 2rem 0;
`;

const ButtonPrimaryOutlinedStyled = styled(ButtonPrimaryOutlined)`
  font-size: 1.1rem;
  padding: .6rem 3.5rem;
`;

// Types
type Props = {
  showResult: any
}