import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import {ThemeProvider as MUIThemeProvider} from '@material-ui/styles';
import {ThemeProvider} from 'styled-components/macro';
import {StylesProvider} from '@material-ui/core';
import {Provider} from 'react-redux';
import {theme} from './core/styles/theme';
import {NormalizeCss} from './core/styles/NormalizeCss';
import {GlobalStyles} from './core/styles/GlobalStyles';
import {store} from './core/redux/store';
import {App} from './features/app/App';


ReactDOM.render(
  <MUIThemeProvider theme={theme}>
    <ThemeProvider theme={theme}>
      <StylesProvider injectFirst>
        <NormalizeCss/>
        <GlobalStyles/>
        <Provider store={store}>
          <App/>
        </Provider>
      </StylesProvider>
    </ThemeProvider>
  </MUIThemeProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
