import {Dictionaries, NotificationType} from './appTypes';

export enum AppActionType {
  // Initialize
  INITIALIZE = 'app/INITIALIZE',
  LOAD = 'app/LOAD',
  SUCCESS = 'app/SUCCESS',
  FAIL = 'app/FAIL',
  SAVE_DICTIONARIES = 'app/SAVE_DICTIONARIES',

  // Global error
  CLEAR_ERROR = 'app/CLEAR_ERROR',
  SET_ERROR = 'app/SET_ERROR',

  // Notifications
  ADD_NOTIFICATION = 'app/ADD_NOTIFICATION_SUCCESS',
  DELETE_NOTIFICATION = 'app/DELETE_NOTIFICATION'
}

export const appAction = {
  initialize: () => (
    {
      type: AppActionType.INITIALIZE as const
    }
  ),
  load: () => (
    {
      type: AppActionType.LOAD as const
    }
  ),
  success: () => (
    {
      type: AppActionType.SUCCESS as const
    }
  ),
  fail: () => (
    {
      type: AppActionType.FAIL as const,
    }
  ),
  saveDictionaries: (dictionaries: Dictionaries) => (
    {
      type: AppActionType.SAVE_DICTIONARIES as const,
      payload: {
        dictionaries
      }
    }
  ),
  clearError: () => (
    {
      type: AppActionType.CLEAR_ERROR as const
    }
  ),
  setError: (message: string, incidentId: string) => (
    {
      type: AppActionType.SET_ERROR as const,
      payload: {
        message,
        incidentId
      }
    }
  ),
  addNotification: (type: NotificationType, message: string) => (
    {
      type: AppActionType.ADD_NOTIFICATION as const,
      payload: {
        type,
        message
      }
    }
  ),
  deleteNotification: (id: string) => (
    {
      type: AppActionType.DELETE_NOTIFICATION as const,
      payload: {
        id
      }
    }
  )
};

export type AppAction =
  ReturnType<typeof appAction.initialize>
  | ReturnType<typeof appAction.load>
  | ReturnType<typeof appAction.success>
  | ReturnType<typeof appAction.fail>
  | ReturnType<typeof appAction.saveDictionaries>
  | ReturnType<typeof appAction.clearError>
  | ReturnType<typeof appAction.setError>
  | ReturnType<typeof appAction.addNotification>
  | ReturnType<typeof appAction.deleteNotification>