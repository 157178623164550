import React from 'react';
import {LayoutStyles} from '../../../../core/styles/common/layout';
import styled from 'styled-components/macro';
import {TypographyStyles} from '../../../../core/styles/common/typography';
import {LogoDenominator} from '../../../../core/components/ui-elements/logos/LogoDenominator';
import {LogoFQ} from '../../../../core/components/ui-elements/logos/LogoFQ';

export const MainSearchContentCompany: React.FC = () => {

  // Styles
  const TS = TypographyStyles;

  return (
    <CardStyled>
      <Inner>

        <TS.TextWrapper>
          <TS.TitleL>
            <TitlePart>Welcome to </TitlePart>

            <LogoFQStyled/>

            <TitlePart>Equity Index</TitlePart>
          </TS.TitleL>
        </TS.TextWrapper>

        <LogoWrapper>
          <span>powered by</span>
          <LogoDenominatorStyled/>
        </LogoWrapper>

        <InfoCard>
          See where you are on the DEI Index and where you want to go
        </InfoCard>

      </Inner>
    </CardStyled>
  );
};

// Styles
const CardStyled = styled(LayoutStyles.Card)`
  display: flex;
  align-items: center;
  justify-content: center;

  flex-grow: 1;
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  width: 32rem;
`;

const TitlePart = styled.div`
  text-align: center;
`

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  margin-bottom: 1rem;
`

const LogoDenominatorStyled = styled(LogoDenominator)`
  & > img {
    width: 9rem;
  }
`

const LogoFQStyled = styled(LogoFQ)`
  position: relative;
  left: -0.2rem;
  
  margin: 1rem 0;
  
  & > img {
    width: 9rem;
  }
`

const InfoCard = styled.div`
  width: 80%;
  
  padding: 1.5rem 2rem;

  background-color: ${({theme}) => theme.palette.custom.primaryPale};
  border-radius: ${({theme}) => theme.shape.borderRadius + 'px'};
  
  text-align: center;
`