import {DictionaryItemWithChildren} from '../features/app/redux/appTypes';

export const dataToReadableString = (value: boolean | string | number) => {
  if (typeof value === 'boolean') {
    return value ? 'Yes' : 'No';
  }

  if (typeof value === 'string' || Array.isArray(value)) {
    if (value == 0) {
      return 'No info';
    }
    return value;
  }
  return value;
};

export const objectNameToTitle = (value: string) => {
  const string = value.split(/(?=[A-Z])/).join(' ').toLowerCase();
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const objectValuesToArrayWithTitleData = (object: Object) => {
  const entries = Object.entries(object);
  return entries.map(entry => (
    {
      title: objectNameToTitle(entry[0]),
      data: dataToReadableString(entry[1])
    }
  ));
};

export const flatChildren = (array: Array<DictionaryItemWithChildren>) => {
  return array.map(item => {
      if (item.children) {

        return [{id: item.id, name: item.name}, ...item.children];

      } else {
        return item;
      }
    }
  ).flat();
};

export const newLineAfterSlash = (text: string) => {
  return text.replace('/', '/\n');
};