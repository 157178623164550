import {getRequest, RequestStatus} from '../../../core/api/api';
import {call, put, takeLatest} from 'redux-saga/effects';
import {standardError} from '../../../helpers/sagaHelpers';
import {v4} from 'uuid';
import {Dictionaries, ErrorCritical, NotificationInterface} from './appTypes';
import {appAction, AppAction, AppActionType} from './appAction';
import {LocalStorageVar} from '../../../core/localStorage';

// Workers
export function* appInitialize(): any {

  try {
    yield put(appAction.load());
    const res = yield call(getRequest, 'fqDictionaries');
    yield put(appAction.saveDictionaries(res));
    yield put(appAction.success());

  } catch (error) {
    yield standardError(error, AppActionType.FAIL);
  }
}

// Watchers
export function* appWatcher() {
  yield takeLatest(AppActionType.INITIALIZE, appInitialize);
}

// Reducer
const dictionariesStorage = localStorage.getItem(LocalStorageVar.dictionaries);

const initialState: AppState = {
  dictionaries: dictionariesStorage ? JSON.parse(dictionariesStorage) : null,
  status: RequestStatus.still,
  error: null,
  notifications: []
};

export const appReducer = (state: AppState = initialState, action: AppAction): AppState => {

  switch (action.type) {

    case AppActionType.LOAD: {
      return {
        ...state,
        status: RequestStatus.loading
      };
    }

    case AppActionType.SUCCESS: {
      return {
        ...state,
        status: RequestStatus.still,
      };
    }

    case AppActionType.FAIL: {
      return {
        ...state,
        status: RequestStatus.fail,
      };
    }

    case AppActionType.SAVE_DICTIONARIES: {
      const {dictionaries} = action.payload;

      localStorage.setItem(LocalStorageVar.dictionaries, JSON.stringify(dictionaries));

      return {
        ...state,
        dictionaries: dictionaries
      };
    }

    case AppActionType.CLEAR_ERROR: {
      return {
        ...state,
        status: RequestStatus.still,
        error: null
      };
    }

    case AppActionType.SET_ERROR: {
      const {message, incidentId} = action.payload;

      return {
        ...state,
        error: {message, incidentId}
      };
    }

    case AppActionType.ADD_NOTIFICATION: {
      const {type, message} = action.payload;

      return {
        ...state,
        notifications: [...state.notifications, {id: v4(), type, message}]
      };
    }

    case AppActionType.DELETE_NOTIFICATION: {
      const {id} = action.payload;

      return {
        ...state,
        notifications: state.notifications.filter(note => note.id !== id)
      };
    }

    default: {
      return state;
    }
  }
};

// Types
export type AppState = {
  dictionaries: Dictionaries | null
  status: RequestStatus
  error: ErrorCritical | null
  notifications: Array<NotificationInterface>
}