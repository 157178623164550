import {getRequest, RequestStatus} from '../../../core/api/api';
import {call, put, takeLatest} from 'redux-saga/effects';
import {standardError} from '../../../helpers/sagaHelpers';
import {databaseAction, DatabaseAction, DatabaseActionType} from './databaseAction';
import {IndustryGraph} from './databaseTypes';

// Saga
export function* databaseWorker(action: ReturnType<typeof databaseAction.databaseLoad>): any {

  const {url, params} = action.payload.databaseRequest;

  try {
    if (url === 'fqIndustries') {
      const res = yield call(getRequest, url, params);
      yield put(databaseAction.databaseSuccess(res));
    }

    if (url === 'fqCompany') {
      const res = yield call(getRequest, url, params);
      yield put(databaseAction.databaseSuccess(res));
    }

  } catch (error: any) {
    yield standardError(error, DatabaseActionType.DATABASE_FAIL);
  }
}

export function* databaseWatcher() {
  yield takeLatest(DatabaseActionType.DATABASE_LOAD, databaseWorker);
}

const initialState: DatabaseState = {
  databaseData: null,
  status: RequestStatus.still,
  error: null,
};

export function databaseReducer(state: DatabaseState = initialState, action: DatabaseAction): DatabaseState {

  switch (action.type) {

    case DatabaseActionType.DATABASE_LOAD: {
      return {
        ...state,
        status: RequestStatus.loading,
      };
    }

    case DatabaseActionType.DATABASE_SUCCESS: {
      const {databaseData} = action.payload;

      return {
        ...state,
        databaseData: databaseData,
        status: RequestStatus.still,
      };
    }

    case DatabaseActionType.DATABASE_FAIL: {


      return {
        ...state,
        status: RequestStatus.fail,
      };
    }

    case DatabaseActionType.DATABASE_CLEAR: {
      return {
        ...state,
        databaseData: null
      };
    }

    default: {
      return state;
    }
  }
}

// Types
export type DatabaseState = {
  databaseData: any | IndustryGraph
  status: RequestStatus
  error: string | null
}

