import styled, {css, keyframes} from 'styled-components/macro';
import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {appAction} from '../../../features/app/redux/appAction';
import {NotificationType, NotificationInterface} from '../../../features/app/redux/appTypes';


export const NotificationSingle: React.FC<Props> = props => {

  const {
    id,
    message,
    type,
  } = props;

  const dispatch = useDispatch();
  const deleteNote = (id: string) => dispatch(appAction.deleteNotification(id));

  const [close, setClose] = useState(false);
  const [barWidth, setBarWidth] = useState<number>(0);
  const [intervalId, setIntervalId] = useState<ReturnType<typeof setInterval> | null>(null);

  useEffect(() => {
    handleStartTimer();
  }, []);

  const handleStartTimer = () => {
    const id = setInterval(() => {
      setBarWidth(prevState => {
        if (prevState < 100) {
          return prevState + 0.5;
        }
        return prevState;
      });
    }, 20);

    setIntervalId(id);
  };

  const handlePauseTimer = () => {
    clearInterval(intervalId as ReturnType<typeof setInterval>);
  };

  const handleCloseNotification = () => {
    handlePauseTimer();
    setClose(true);
    setTimeout(() => {
      deleteNote(id);
    }, 400);
  };

  useEffect(() => {
    if (barWidth === 100) {
      handleCloseNotification();
    }
  }, [barWidth]);

  return (
    <NotificationItem type={type} onMouseEnter={handlePauseTimer} onMouseLeave={handleStartTimer} exit={close}>
      <p>{message}</p>
      <Bar style={{width: `${barWidth}%`}}/>
    </NotificationItem>
  );
};

// Animations
const slideLeft = keyframes`
  0% {
    margin-left: 120%;
  }

  100% {
    margin-left: 0;
  }
`;

const slideRight = keyframes`
  0% {
    margin-left: 0;
  }

  100% {
    margin-left: 120%;
  }
`;

// Styles
const Bar = styled.div`
  height: .5rem;
`;

const NotificationItem = styled.div<{ type: NotificationType, exit: boolean }>`
  overflow: hidden;

  margin-bottom: 1rem;

  width: 300px;

  box-shadow: ${({theme}) => theme.shadows['1']};

  background-color: ${({theme}) => theme.palette.common.white};

  animation: ${slideLeft} .5s linear forwards;
  animation: ${({exit}) =>
          exit && css`${slideRight} .5s linear forwards`
  };

  & p {
    margin: 0;
    padding: 1rem;
  }

  & ${Bar} {
    background-color: ${({theme, type}) => {
      if (type === 'info') return theme.palette.primary.main;
      if (type === 'success') return theme.palette.success.main;
      if (type === 'warning') return theme.palette.warning.main;
      if (type === 'error') return theme.palette.error.main;
    }}
`;

// Types
type Props = NotificationInterface