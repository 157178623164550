import ReactDOM from 'react-dom';
import React, {memo} from 'react';
import styled from 'styled-components/macro';
import {LayoutStyles} from '../../styles/common/layout';
import {ReactComponent as CloseIcon} from '../../assets/svg/close.svg';
import {Backdrop} from './Backdrop';

const ModalOverlay: React.FC<ModalOverlay> = props => {

  const {
    isActive,
    toggleModal,
    content,
    width,
  } = props;

  const component = (
    <ModalMain isActive={isActive} width={width}>
      <CardStyled id="modal">
        {content}
      </CardStyled>
      {toggleModal && (
        <CloseButton>
          <CloseIcon onClick={toggleModal}/>
        </CloseButton>
      )}
    </ModalMain>
  );

  const container = document.getElementById('modal-portal') as HTMLElement;

  return ReactDOM.createPortal(component, container);
};

export const Modal: React.FC<ModalProps> = memo(props => {

  const {
    show,
    toggleModal,
    content,
    closeOnBackdrop = true,
    width = 80 // Percent
  } = props;

  return (<>
    <Backdrop
      isActive={show}
      onClick={closeOnBackdrop ? toggleModal : undefined}
    />
    <ModalOverlay
      isActive={show}
      toggleModal={toggleModal}
      content={content}
      width={width}
    />
  </>);
});

// Styles
const ModalMain = styled.div<{ isActive: boolean, width: number }>`
  position: fixed;
  top: 10rem;
  left: ${({width}) => (100 - width) / 2}%;
  z-index: 1001;

  display: ${({isActive}) => isActive ? 'block' : 'none'};

  align-items: center;
  justify-content: center;

  width: ${({width}) => width}%;
`;

const CardStyled = styled(LayoutStyles.Card)`
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  
  padding: 4rem 1rem 1rem 1rem;

  overflow: auto;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;

  width: 2rem;
  height: 2rem;
  cursor: pointer;

  & svg {

    width: 2rem;
    height: 2rem;

    fill: ${({theme}) => theme.palette.primary.main};
  }

  &:hover {
    & svg {
      fill: ${({theme}) => theme.palette.secondary.main}
    }
  }
`;

// Types
type ModalProps = {
  show: boolean
  toggleModal: () => void
  content: JSX.Element
  closeOnBackdrop?: boolean
  width?: number
}

type ModalOverlay = {
  isActive: boolean
  toggleModal: () => void
  content: JSX.Element
  width: number
}