import React from 'react';
import {Field, FieldProps} from 'formik';
import styled from 'styled-components/macro';
import {MenuItem, Select} from '@material-ui/core';

export const SelectField: React.FC<Props> = props => {

  const {
    label,
    name,
    validate,
    options
  } = props;

  return (
    <Field name={name}>
      {({field, form, meta}: FieldProps) => (
        <FieldInner>
          <Label>{label}</Label>
          <SelectStyled
            {...field}
            variant="outlined"
          >
            {options && options.map((option: any) => (
              <MenuItem value={option.id}>{option.name}</MenuItem>
            ))}
          </SelectStyled>
          <ErrorBox>
            <span>{meta.error}</span>
          </ErrorBox>
        </FieldInner>
      )}
    </Field>
  );
};

// Styles
const FieldInner = styled.div`
  position: relative;

  margin-bottom: 2rem;
`;

const Label = styled.div`
  margin-bottom: .5rem;
  padding-left: .5rem;
  color: ${({theme}) => theme.palette.custom.secondaryGrey}
`;

const SelectStyled = styled(Select)`
  &.MuiOutlinedInput-root {
    min-width: 100%;
    width: 100%;
    
    box-shadow: ${({theme}) => theme.shadows[1]};
  }
  
  &.MuiSelect-outlined {
    border: 1px solid ${({theme}) => theme.palette.custom.border};
    border-color: ${({theme}) => theme.palette.custom.border};
  }


`;

const ErrorBox = styled.div`
  position: absolute;
  padding-left: .5rem;

  & > span {
    font-size: .8rem;
    color: red;
  }
`;

// Props
type Props = {
  label: string
  name: string
  validate?: any
  options: any
}