import React, {DetailedHTMLProps, InputHTMLAttributes} from 'react';
import styled from 'styled-components/macro';

export const Input: React.FC<Props> = props => {

  const {
    label,
    error,
    ...restProps
  } = props;

  return (
    <Container>
      <InputStyled {...restProps}/>
    </Container>
  );
};

// Styles
const Container = styled.div`
  position: relative;

  width: 100%;
`;

const InputStyled = styled.input<DefaultInput>`
  position: relative;
  padding: 18.5px 14px; // Material UI standard padding
  
  width: 100%;

  border: 1px solid ${({theme}) => theme.palette.custom.border};
  border-radius: ${({theme}) => theme.shape.borderRadius + 'px'};
  box-shadow: ${({theme}) => theme.shadows[1]};
  
  &:hover {
    border-color: ${({theme}) => theme.palette.grey[500]};
  }

  &:focus {
    outline: none;
    border-color: ${({theme}) => theme.palette.primary.main};
  }
`;

// Types
type DefaultInput = InputHTMLAttributes<HTMLInputElement>;

type Props = DefaultInput & {
  label?: string
  error?: string
};