import React, {memo} from 'react';
import {TypographyStyles} from '../../../core/styles/common/typography';
import styled from 'styled-components/macro';
import {ErrorCritical} from '../redux/appTypes';

export const CriticalErrorModalContent: React.FC<Props> = memo(props => {

  const {
    error
  } = props;

  // Styles
  const TS = TypographyStyles;

  return (<>
    {error &&
    <ErrorWrapper>
      <TS.Title marginBottom="m">
        {error.message}
      </TS.Title>

      <Error>
        <span>Error code: </span><ErrorCode>{error.incidentId}</ErrorCode>
      </Error>

      <TS.Paragraph>
        Seems like something went wrong. If this happens again please take a screenshot and send to <a
        href="mailto:support@denominator.com">support@denominator.com</a>
      </TS.Paragraph>
    </ErrorWrapper>
    }
  </>);
});

// Styles
const ErrorWrapper = styled.div`
  overflow: auto;
`;

const Error = styled.div`
  margin-bottom: 1rem;
`;

const ErrorCode = styled.span`
  font-weight: 500;
  color: ${({theme}) => theme.palette.error.main};
`

// Types
type Props = {
  error: ErrorCritical | null
}