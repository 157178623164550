import React from 'react';
import {Field, FieldProps} from 'formik';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import styled from 'styled-components/macro';

export const PhoneField: React.FC<Props> = props => {

  const {
    label,
    name,
    validate,
  } = props;

  return (
    <Field name={name}>
      {({field, form, meta}: FieldProps) => (
        <FieldInner>
          <Label>{label}</Label>
          <PhoneInputWrapper>
            <PhoneInput
              specialLabel={''}
              country={'us'}
              inputProps={{
                name: field.name,
              }}
              value={field.value}
              onChange={(phone, data, e, formattedValue) => form.setFieldValue(field.name, formattedValue)}
            />
          </PhoneInputWrapper>
          <ErrorBox>
            <span>{meta.error}</span>
          </ErrorBox>
        </FieldInner>
      )}
    </Field>
  );
};
// Styles
const FieldInner = styled.div`
  position: relative;

  margin-bottom: 2rem;
`;

const Label = styled.div`
  margin-bottom: .5rem;
  padding-left: .5rem;
  color: ${({theme}) => theme.palette.custom.secondaryGrey}
`;

const PhoneInputWrapper = styled.div`
  & .form-control {
    padding: 18.5px 14px 18.5px 60px; // Material UI standard padding modified left for flag

    width: 100%;

    border: 1px solid ${({theme}) => theme.palette.custom.border};
    border-radius: ${({theme}) => theme.shape.borderRadius + 'px'};
    box-shadow: ${({theme}) => theme.shadows[1]};

    transition: none;

    &:hover {
      border-color: ${({theme}) => theme.palette.primary.main};
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 1px ${({theme}) => theme.palette.primary.main};
      border-color: ${({theme}) => theme.palette.primary.main};
    }
  }
`;

const ErrorBox = styled.div`
  position: absolute;
  padding-left: .5rem;

  & > span {
    font-size: .8rem;
    color: red;
  }
`;

// Type
type Props = {
  label: string
  name: string
  validate?: any
}