import styled from 'styled-components/macro';

const Title = styled.div<TextProps>`
  font-size: ${({size}) => {
    if (size === 'xs') return '.6rem';
    if (size === 's') return '.8rem';
    if (size === 'm') return '1rem';
    if (size === 'l') return '1.2rem';
    if (size === 'xl') return '1.4rem';
    if (size === 'xxl') return '1.6rem';
    if (size === 'xxxl') return '1.8rem';
    else return '1.2rem'; // Default value
  }};

  font-weight: ${({weight}) => {
    if (weight === 'thin') return '200';
    if (weight === 'regular') return '400';
    if (weight === 'bold') return '600';
    if (weight === 'extraBold') return '800';
    else return '600'; // Default value
  }};

  text-transform: ${({transform}) => {
    if (transform === 'none') return 'none';
    if (transform === 'capital') return 'capitalize';
    if (transform === 'upper') return 'uppercase';
    if (transform === 'lower') return 'lowercase';
    else return 'none'; // Default value
  }};

  color: ${({theme, color}) => {
    if (color === 'primary') return theme.palette.primary.main;
    if (color === 'secondary') return theme.palette.secondary.main;
    else return theme.palette.common.black; // Default value
  }};

  white-space: pre-wrap;

  margin-bottom: ${({marginBottom}) => {
    if (marginBottom === 'xs') return '.6rem';
    if (marginBottom === 's') return '.8rem';
    if (marginBottom === 'm') return '1rem';
    if (marginBottom === 'l') return '1.2rem';
    if (marginBottom === 'xl') return '1.4rem';
    if (marginBottom === 'xxl') return '1.6rem';
    if (marginBottom === 'xxxl') return '1.8rem';
    else return '0'; // Default value
  }};
`;

// TODO: Remove all title variants. Replace everything with Title.
const TitleUppercaseXXL = styled(Title)`
  font-size: 2rem;
  text-transform: uppercase;
`;

const TitleXXL = styled(Title)`
  font-size: 2rem;
`;

const TitleXL = styled(Title)`
  font-size: 1.8rem;
`;

const TitleUppercaseL = styled(Title)`
  font-size: 1.6rem;
  text-transform: uppercase;
`;

const TitleL = styled(Title)`
  font-size: 1.6rem;
`;

const TitleB = styled(Title)`
  font-size: 1.4rem;
`;

const TitleM = styled(Title)`
  font-size: 1.2rem;
`;

const TitleS = styled(Title)`
  font-size: 1rem;
`;

const TextWrapper = styled.div`
  margin-bottom: 1rem;
`;

const Subtitle = styled.div`
  font-family: ${({theme}) => theme.typography.fontFamilyAlt};
  font-weight: 400;
  color: ${({theme}) => theme.palette.primary.dark};
  
  line-height: 1.6;
`

const SubtitleXL = styled(Subtitle)`
  font-size: 1.6rem;
`

const SubtitleL = styled(Subtitle)`
  font-size: 1.4rem;
`

const SubtitleM = styled(Subtitle)`
  font-size: 1.2rem;
`

const SubtitleS = styled(Subtitle)`
  font-size: 1rem;
`

const Paragraph = styled.p`
  margin-bottom: 1.2rem;
`

const Link = styled.a`
  color: #0000EE;
  text-decoration-line: underline;
  
  cursor: pointer;
  
  &:active {
    color: #FF0000;
  }
`

export const TypographyStyles = {
  Title,
  TitleUppercaseXXL,
  TitleUppercaseL,
  TitleXXL,
  TitleXL,
  TitleL,
  TitleB,
  TitleM,
  TitleS,
  TextWrapper,
  SubtitleXL,
  SubtitleL,
  SubtitleM,
  SubtitleS,
  Paragraph,
  Link
};

// Types
type TextProps = {
  size?: Size
  weight?: FontWeight
  transform?: TextTransform
  color?: Color
  marginBottom?: Size
}

type Color = 'primary' | 'secondary'
type Size = 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl' | 'xxxl'
type FontWeight = 'thin' | 'regular' | 'bold' | 'extraBold'
type TextTransform = 'none' | 'capital' | 'upper' | 'lower'