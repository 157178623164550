import React from 'react';
import styled from 'styled-components/macro';

export const LogoFQ: React.FC<Props> = props => {

  const {
    onClick,
    className
  } = props;

  return (
    <Container className={className} onClick={onClick}>
      <img src="/fq_logo.png" alt={'Denominator logo'}/>
    </Container>
  );
};

// Styles
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

// Types
type Props = {
  onClick?: (e: React.SyntheticEvent) => void
  className?: string
}
