import React, {InputHTMLAttributes} from 'react';
import styled from 'styled-components/macro';

export const Checkbox: React.FC<Props> = (props) => {

  // TODO: Make the checkbox apart from the label. As for now if there is no label there is no checkbox.

  const {
    type,
    children,
    className,
    ...restProps
  } = props;

  return (
    <CheckboxContainer className={className}>
      <input
        readOnly={true}
        type={'checkbox'}
        {...restProps}
      />
      {/* Real checkbox */}
      <span/>
    </CheckboxContainer>
  );
};

// Styles
const CheckboxContainer = styled.label`
  cursor: pointer;

  display: flex;
  align-items: center;

  margin-right: 0.5rem;

  & > input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  & > span {
    width: inherit;
    display: inline-flex;
    align-items: center;
    user-select: none;
  }

  & > span::before {
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid ${({theme}) => theme.palette.grey[300]};

    background-color: ${({theme}) => theme.palette.grey[100]};
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;

    border-radius: 3px;
  }

  & > input:not(:disabled):not(:checked) + span:hover::before {
    border-color: ${({theme}) => theme.palette.grey[500]};
  }

  & > input:not(:disabled):active + span::before {
    background-color: ${({theme}) => theme.palette.grey[500]};
    border-color: ${({theme}) => theme.palette.grey[700]};
  }

  & > input:focus + span::before {
    box-shadow: ${({theme}) => theme.shadows[0]};
  }

  & > input:focus:not(:checked) + span::before {
    border-color: ${({theme}) => theme.palette.grey[700]};
  }

  & > input:checked + span::before {
    border-color: ${({theme}) => theme.palette.primary.main};
    background-color: ${({theme}) => theme.palette.primary.main};
    background-size: 65% 65%;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
  }

  & > input:disabled + span::before {
    background-color: ${({theme}) => theme.palette.grey[700]};
  }
`;

// Types
type DefaultInputPropsType = InputHTMLAttributes<HTMLInputElement>;

type Props = DefaultInputPropsType & {
  children?: string
};
