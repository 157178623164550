import React from 'react';
import {ReactComponent as TriangleSVG} from '../../../assets/svg/triangle.svg';
import {useStyles} from './useStyles';
import _ from 'lodash';
import styled from 'styled-components/macro';
import {LocalStorageVar} from '../../../localStorage';

export const Benchmark: React.FC<Props> = props => {

  const {
    data,
    peerGroup
  } = props;

  const {
    containerRow,
    part, partA, partB, partC,
    gap5,
    mrgTop50,
    localLegendItem,
  } = useStyles();

  const tab = localStorage.getItem(LocalStorageVar.mainSearchCurrentTab);
  const isCompanyTab = tab === 'company';

  const renderIndicator = (percentage: number, isCompany?: boolean) => (
    <Indicator score={percentage}>
      {isCompany ?
        <IndicatorTriangle/>
        :
        <IndicatorCircle/>}
    </Indicator>
  );

  // TODO: Refactor this!!!
  const renderRatingBar = (name: string) => (
    <div className={`${part}  ${name === 'C' ? partC : name === 'B' ? partB : partA}`}>
      {(name === 'C' && tab === 'Country') && <span>D</span>}
      {[1, 2, 3].map((n) => (
        <span key={Math.random()}>
                    {tab === 'company' && _.times(n, () => name)}
                </span>
      ))}
    </div>
  );

  return (
    <MainWrapper>
      <IndicatorWrapper>
        {(data?.peerRating && isCompanyTab) && renderIndicator(peerGroup ? peerGroup : data.peerRating.score)}
        {(data?.companyRating && isCompanyTab) && renderIndicator(data.companyRating.score, true)}
        {!isCompanyTab && renderIndicator(data.score, true)}
      </IndicatorWrapper>

      {/* Progress bar */}
      <ProgressBarWrapper>
        <ProgressBarVisual>
          {renderRatingBar('C')}
          {renderRatingBar('B')}
          {renderRatingBar('A')}
        </ProgressBarVisual>

        {/* Simple rating */}

      </ProgressBarWrapper>

      <InfoContainer>
        <div className={localLegendItem}>
          <IndicatorTriangle/>
          <span>Company</span>
        </div>
        <div className={localLegendItem}>
          <IndicatorCircle/>
          <span>Peer Group</span>
        </div>
      </InfoContainer>

    </MainWrapper>);
};

// Styles
const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const IndicatorWrapper = styled.div`
  position: relative;
  height: 3rem;
`;

const Indicator = styled.div<{ score: number }>`
  position: absolute;
  bottom: 1px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  left: ${({score}) => `${score}%`};

  width: 0;
`;

const IndicatorScore = styled.span<{ score: number }>`
  position: absolute;
  left: ${({score}) => `calc(${score}% - 10px)`};
`;

const IndicatorCircle = styled.div`
  width: 20px;
  height: 20px;

  background-color: ${({theme}) => theme.palette.custom.secondaryGrey};
  border-radius: 50%;
  box-shadow: ${({theme}) => theme.shadows[5]};
`;

const IndicatorTriangle = styled(TriangleSVG)`
  width: 20px;
  height: 20px;
  fill: ${({theme}) => theme.palette.custom.secondaryAccent};
  filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, .5));
`;

const ProgressBarWrapper = styled.div`
  position: relative;
  
  padding-bottom: 4rem;
`;

const ProgressBarVisual = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  margin-bottom: .5rem;

  font-size: .8rem;
  color: ${({theme}) => theme.palette.grey[500]};

  & div:nth-child(1) {
    flex-basis: 35%;
  }

  & div:nth-child(2) {
    flex-basis: 32.5%;
  }

  & div:nth-child(3) {
    flex-basis: 32.5%;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  
  & > div:first-child {
    margin-right: 1rem;
  }
`

// Types
type BenchmarkItem = {
  quality: string
  rating: string
  score: number
  strength: number
}

type Props = {
  data: any
  peerGroup?: number
}

type Data = {
  companyRating: BenchmarkItem
  peerRating: BenchmarkItem
}