import React from 'react';
import {TypographyStyles} from '../../styles/common/typography';
import styled from 'styled-components/macro';

export const ModalContentTermsOfUse: React.FC = () => {

  // Styles
  const TS = TypographyStyles;

  return (<>
    <TitleStyled color="primary" size="xxxl" marginBottom="xl">Terms of Use - Denominator</TitleStyled>

    <Content>
      <TS.Paragraph>
        Any view, use, or access to website denominator.com and any subdomains, or any applications, functionalities,
        content, materials, or other online services provided by Denominator (collectively, the “Solution”), whether as
        a guest or a registered user, you are agreeing to the following terms and conditions (“Terms of Use”).
      </TS.Paragraph>

      <TS.Title marginBottom="xs">Acceptance of Terms of Use</TS.Title>
      <TS.Paragraph>
        Without agreeing to these Terms of Use, you are not permitted to use the Solution. Denominator can change these
        Terms of Use at any time without any notice to you. It is your responsibility to review these Terms of Use, as
        it creates a binding legal agreement between you and Denominator. If you use the Solution after we have changed
        any of the Terms of Use, you are agreeing to all changes.
      </TS.Paragraph>

      <TS.Title marginBottom="xs">Account and Security</TS.Title>
      <TS.Paragraph>By accessing the Solution with user credentials, you represent and warrant that you have been
        authorized to do so by Denominator and are doing so only using credentials that have been issued to you by
        Denominator. You may not use any account belonging to any other person without Denominator’s express, prior,
        written permission. You have complete responsibility for your account and everything that happens on your
        account. Therefore, you need to be careful with your password and if you find out that someone has access to, or
        is using your account without your permission, you must let us know promptly. Your access is non-transferable
        and doing so could subject you to civil or criminal liability. We are not liable for any damages or losses
        caused by someone using your account without your permission.
      </TS.Paragraph>

      <TS.Title>Acceptable Use</TS.Title>
      <TS.Paragraph>You must not:</TS.Paragraph>
      <TS.Paragraph>
        <List>
          <li>Use the Solution to copy, store, host, transmit, send, use, publish, or distribute any material which
            consists of (or is linked to) any spyware, computer virus, Trojan horse, worm, keystroke logger, rootkit, or
            other malicious computer software.
          </li>
          <li>Conduct any automated data collection activities (including scraping, data mining, data extraction, and
            data harvesting) on or in relation to our Solution.
          </li>
          <li>Sell, rent, or sub-license material from the Solution.</li>
          <li>Reproduce, duplicate, copy, or otherwise exploit material on our Solution for a commercial purpose.
          </li>
          <li>Violate or attempt to violate the Solution’s security mechanisms, attempt to gain unauthorized access to
            the Solution or assist others to do so, or otherwise breach the security of the Solution or corrupt the
            Solution in any way.
          </li>
        </List>
      </TS.Paragraph>
      <TS.Paragraph>We monitor use of the Solution and reserve the right to suspend, revoke, deny, disable, or terminate
        your access if you violate any provisions of these Terms of Use or if your or behavior exceeds reasonable
        limits, as determined in our sole discretion.
      </TS.Paragraph>

      <TS.Title>Proprietary Materials</TS.Title>
      <TS.Paragraph>Intellectual property rights (“Intellectual Property Rights”) shall be construed as the unlimited
        propriety rights and intellectual property rights of any kind whatsoever related to the Solution, including but
        not limited to know-how, user feedback, copyrights (including all software IP, e.g. object codes and source
        codes), patent rights, data analyses, trademark rights, utility model rights, inventions, product methods and
        design rights belonging to Denominator.
        You agree not to modify, publish, transmit, participate in the transfer or sale of, create derivative works of,
        or in any way exploit, in whole or in part, any Proprietary Materials. Proprietary Materials may only be
        accessed through the Platform, and not by or from any other site or means. The right of access to the Platform
        does not grant to you any right to download or store any Proprietary Materials in any medium.
        No posting, copying, transmission, retransmission, distribution, redistribution, publication, republication,
        decomplication, disassembling, reverse engineering, or otherwise reproducing, storing, transmitting, modifying,
        or commercially exploiting any Proprietary Materials in any form or by any means, for any purpose, is permitted
        without our express written permission.
      </TS.Paragraph>

      <TS.Title>User Driven Content</TS.Title>
      <TS.Paragraph>“User Driven Content” means material (including text, images, and audio material) that you submit to
        the Solution, for whatever purpose.
        You grant to us a worldwide, irrevocable, non-exclusive, royalty-free license to use, reproduce, adapt, publish,
        translate, communicate, publicly perform, publicly display, and distribute your User Driven Content. You also
        grant to us the right to sub-license these rights, and the right to bring an action for infringement of these
        rights.
        Your User Driven Content must not be illegal or unlawful, must not infringe any third party’s legal rights, and
        must not be capable of giving rise to legal action whether against you or us or a third party (in each case
        under any applicable law). You must not submit any User Driven Content that is or has been the subject of any
        legal proceedings or other similar complaint.
      </TS.Paragraph>

      <TS.Title>Warranty Disclaimer</TS.Title>
      <TS.Paragraph>
        <p>We do not warrant the accuracy or completeness of the data and information in the Solution.
          Solution is used at your own risk and any data or material received by using Solution are obtained at own
          risk. Denominator and its affiliates, partners, and suppliers disclaim all warranties of any kind, whether
          implied or expressed including, but not limited to, non-infringement and fit for a particular usage or
          purpose.
          Denominator and its affiliates, partners, and suppliers make no warranty that solution will be error free,
          timely, secure, meet your requirements, or that Denominator will address any errors. Denominator is not
          responsible for any damages resulting from use of Solution or any data or information received from the
          Solution including, but not limited to, bugs, viruses or other destructive code and damages.</p>
        <p><b>Export Controls.</b> Solution may be subject to export laws and regulations of the United
          States and other
          jurisdictions. Each party represents that it is not named on any U.S. government denied-party list. You shall
          not access or use the Solution in a U.S.-embargoed country or in violation of any U.S. export law or
          regulation.
          Privacy. Any personal information submitted in connection with your use of the Solution is subject to our
          privacy policy found at <a href="https://www.denominator.com" target="_blank">https://www.denominator.com</a> (the “Privacy Policy”).</p>
        <p><b>Non-Waiver.</b> Failure by Denominator to enforce any provision(s) of these Terms of Use
          will not be construed as
          a waiver of any provision or right.</p>
        <p><b>Severability.</b> If any provision of these Terms of Use is found to be illegal, void, or
          unenforceable, then
          that provision will be deemed severable from these Terms of Use and will not affect the validity and
          enforceability of any remaining provisions of these Terms of Use.</p>
        <p><b>Governing Law and Jurisdiction.</b> These Terms of Use, and all claims or causes of action
          (whether in contract,
          tort, or statute) that may be based upon, arise out of, or relate to these Terms of Use, or the performance
          under these Terms of Use, are governed by and will be construed and enforced in accordance with the laws of
          the State of New York.</p>
        <p><b>Limitation of Liability.</b> Denominator and its affiliates and partners to the fullest
          extent permitted under
          law, including suppliers and partners have no obligation or liability (whether arising in contract, warranty,
          tort (including negligence), product liability or otherwise) for any indirect, incidental, special, punitive,
          or consequential damages or liabilities (including, but not limited to, any loss of data, revenue or profit)
          arising from or related to your use of the solution or any content provided by or through the solution, even
          if we have been advised of the possibility of such damages in advance. some states do not allow the limitation
          or exclusion of incidental, consequential or other types of damages, so some of the above limitations may not
          apply to you. notwithstanding anything to the contrary contained herein, Denominator’s liability and the
          liability of each of its officers, directors, investors, employees, agents, advertisers, licensors, suppliers,
          service providers and other contractors to you or any third parties under any circumstance is limited to a
          maximum amount of $200.</p>
        <p><b>Indemnity.</b> You agree to indemnify and hold harmless Denominator and its affiliates,
          suppliers, partners,
          officers, agents, and employees from and against any claim, demand, losses, damages, or expenses (including
          reasonable attorney’s fees) arising from any User Driven Content, your use of the Solution, your connection to
          the Solution, your violation of these Terms of Use or your violation of any rights of any third-party. </p>
        <p><b>Breach and remedies.</b> You acknowledge that a breach of these Terms of Use may cause us
          irreparable damage, for which the award of damages would not be adequate compensation. Consequently, you agree
          that we may institute an action to enjoin you from all acts in violation of those provisions, which remedy
          will be cumulative and not exclusive, and we may seek the entry of an injunction enjoining any breach or
          threatened breach of those provisions, in addition to any other relief to which we may be entitled at law or
          in equity.
          Revised July 25, 2021</p>
      </TS.Paragraph>
    </Content>
  </>);
};

// Styles
const Content = styled.div`
  overflow-x: auto;

  padding: 0 1rem;
`;

const TitleStyled = styled(TypographyStyles.Title)`
  text-align: center;
`;

const List = styled.ul`
  list-style: square outside;
  padding-left: 5rem;

  & li:not(:last-child) {
    padding-bottom: .5rem;
  }
`;