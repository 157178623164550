import React from 'react'
import {Theme} from '@material-ui/core'
import styled from 'styled-components/macro'

export const RatingDot: React.FC<Props> = React.memo(props => {

    const {
        ratingValue,
        className
    } = props


    return (
        <Main className={className} rating={ratingValue}/>
    )
})

// Styles
const getColorByRating = (ratingValue: RatingValue, theme: Theme) => {
    if (ratingValue === 'AAA') return theme.palette.rating.healthy.aaa
    if (ratingValue === 'AA') return theme.palette.rating.healthy.aa
    if (ratingValue === 'A') return theme.palette.rating.healthy.a
    if (ratingValue === 'BBB') return theme.palette.rating.balanced.bbb
    if (ratingValue === 'BB') return theme.palette.rating.balanced.bb
    if (ratingValue === 'B') return theme.palette.rating.balanced.b
    if (ratingValue === 'CCC') return theme.palette.rating.problematic.ccc
    if (ratingValue === 'CC') return theme.palette.rating.problematic.cc
    if (ratingValue === 'C') return theme.palette.rating.problematic.c
    if (ratingValue === 'D') return theme.palette.rating.bad.d
    return 'grey'
}

const Main = styled.div<MainStyled>`
  width: .6rem;
  height: .6rem;
  border-radius: 50%;
  background-color: ${({rating, theme}) => getColorByRating(rating, theme)}
`

// Types
type Props = {
    ratingValue: RatingValue
    className?: string
}

type MainStyled = {
    rating: RatingValue
}

export type RatingValue = 'AAA' | 'AA' | 'A' | 'BBB' | 'BB' | 'B' | 'CCC' | 'CC' | 'C' | 'D'

