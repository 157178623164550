import React, {useState} from 'react';
import {flatChildren, newLineAfterSlash} from '../../../../../helpers/dataHelpers';
import styled from 'styled-components/macro';
import {useSelector} from 'react-redux';
import {AppState} from '../../../../app/redux/appDuck';
import {RootState} from '../../../../../core/redux/store';
import {Modal} from '../../../../../core/components/modal/Modal';
import {ButtonPrimaryOutlined} from '../../../../../core/components/ui-elements/buttons';
import {IndustryOptions} from '../../../../mainSearch/components/content/IndustryOptions';
import {DatabaseState} from '../../../redux/databaseDuck';

export const IndustryPerformanceChartVertical: React.FC<Props> = props => {

  const {
    industryOptionsShowResultButtonAction
  } = props;

  // Selectors
  const {dictionaries} = useSelector<RootState, AppState>(state => state.app);
  const {databaseData} = useSelector<RootState, DatabaseState>(state => state.database);

  const [modalActive, setModalActive] = useState(false);

  const toggleModal = () => {
    setModalActive(!modalActive);
  };

  // Calculate the highest rating.
  let highestRating = 0;
  databaseData.industryGraph.forEach((item: any) => {
    if (item.rating > highestRating) {
      highestRating = item.rating;
    }
  });

  return (<>
    <Modal
      show={modalActive}
      toggleModal={toggleModal}
      content={<IndustryOptions
        showResult={industryOptionsShowResultButtonAction ? industryOptionsShowResultButtonAction : () => {
        }}/>}
      width={90}
    />
    <ButtonPrimaryOutlinedStyled onClick={toggleModal}>Choose another industry</ButtonPrimaryOutlinedStyled>
    <PerformanceChartContainer>
      <PerformanceChart>
        <PerformanceChartBackground>
          <Line/><Line/><Line/><Line/><Line/><Line/><Line/><Line/><Line/><Line/><Line/>
        </PerformanceChartBackground>
        {databaseData.industryGraph.map((graph: any, index: any) => (
          <ChartBar
            key={index}
            rating={graph.rating}
            highestRating={highestRating}
            isSelected={graph.isSelected}
          >
            {graph.isSelected && (
              <Rating>{graph.rating}</Rating>
            )}
            <IndustryName>
              {dictionaries && newLineAfterSlash(flatChildren(dictionaries.industries).find(dict => graph.industryId === dict.id)!.name)}
            </IndustryName>
          </ChartBar>
        ))}
      </PerformanceChart>

    </PerformanceChartContainer>

  </>);
};

// Styles
const PerformanceChartContainer = styled.div`
  padding-bottom: 5rem;
`;

const ButtonPrimaryOutlinedStyled = styled(ButtonPrimaryOutlined)`
  margin-bottom: 2rem;
`;

const PerformanceChart = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-around;

  position: relative;

  height: 25rem;
`;

const PerformanceChartBackground = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: absolute;

  height: 100%;
  width: 100%;
`;

const Line = styled.div`
  height: 1px;
  width: 100%;

  background-color: ${({theme}) => theme.palette.custom.primary};
`;

const ChartBar = styled.div<{ rating: number, highestRating: number, isSelected: boolean }>`
  position: relative;

  // 100% is 25rem (PerformanceChart), so 1% is .25rem. 100 / highestRating - is for showing the highest rating as 100%.
  width: 4%;
  height: ${({rating, highestRating}) => `${rating * 100 / highestRating * 0.25}rem`};
  border-radius: ${({theme}) => `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`};

  background-color: ${({theme, isSelected}) =>
          isSelected ? theme.palette.custom.secondaryAccent : theme.palette.custom.primary};
`;

const Rating = styled.div`
  position: absolute;
  top: -3rem;

  width: 100%;
  text-align: center;

  font-size: 2rem;
`;

const IndustryName = styled.div`
  height: 4rem;

  position: absolute;
  bottom: -4.5rem;

  font-size: .5rem;
  font-weight: 600;
`;

// Types
type Props = {
  industryOptionsShowResultButtonAction?: any
}