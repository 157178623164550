import {createTheme, Theme} from '@material-ui/core';

// TODO: Add rating colors gradation, now they are the same.
export const theme = createTheme({
  typography: {
    fontFamily: 'Poppins, sans-serif',
    fontFamilyAlt: 'Montserrat, sans-serif'
  },
  palette: {
    primary: {
      main: '#000000'
    },
    secondary: {
      main: '#4e679f'
    },
    rating: {
      healthy: {
        aaa: '#4e679f',
        aa: '#4e679f',
        a: '#4e679f'
      },
      balanced: {
        bbb: '#e3b79b',
        bb: '#e3b79b',
        b: '#e3b79b'
      },
      problematic: {
        ccc: '#d3d3d3',
        cc: '#d3d3d3',
        c: '#d3d3d3'
      },
      bad: {
        d: '#FFD2D2'
      }
    },
    gender: {
      male: '#2B69F0',
      female: '#D765FF'
    },
    race: {
      middleEastern: '#A3E5B2',
      hispanic: '#de7a7a',
      black: '#FFC165',
      asian: '#c982f6',
      caucasian: '#A4C0FF',
      indigenous: '#ab8169'
    },
    custom: {
      border: '#ebeaec',
      appBackground: '#F9FAFC',
      primary: '#d3d3d3',
      primaryAccent: '#79878b',
      primaryPale: '#EBEBED',
      secondary: '#9eabb5',
      secondaryAccent: '#4e679f',
      secondaryGrey: '#707372',
      tertiary: '#e3b79b',
    },
  },
  shadows: [
    'none',
    '0px 0px 10px 1px rgba(0,0,0,0.1)',
    '0px 0px 10px 2px rgba(0,0,0,0.1)',
    '0px 0px 10px 3px rgba(0,0,0,0.1)',
    '0px 0px 10px 4px rgba(0,0,0,0.1)',
    '0px 0px 10px 5px rgba(0,0,0,0.1)',
    '0px 0px 10px 6px rgba(0,0,0,0.1)',
    '0px 0px 10px 7px rgba(0,0,0,0.1)',
    '0px 0px 10px 8px rgba(0,0,0,0.1)',
    '0px 0px 10px 9px rgba(0,0,0,0.1)',
    '0px 0px 10px 10px rgba(0,0,0,0.1)',
    '0px 0px 10px 11px rgba(0,0,0,0.1)',
    '0px 0px 10px 12px rgba(0,0,0,0.1)',
    '0px 0px 10px 13px rgba(0,0,0,0.1)',
    '0px 0px 10px 14px rgba(0,0,0,0.1)',
    '0px 0px 10px 15px rgba(0,0,0,0.1)',
    '0px 0px 10px 16px rgba(0,0,0,0.1)',
    '0px 0px 10px 17px rgba(0,0,0,0.1)',
    '0px 0px 10px 18px rgba(0,0,0,0.1)',
    '0px 0px 10px 19px rgba(0,0,0,0.1)',
    '0px 0px 10px 20px rgba(0,0,0,0.1)',
    '0px 0px 10px 21px rgba(0,0,0,0.1)',
    '0px 0px 10px 22px rgba(0,0,0,0.1)',
    '0px 0px 10px 23px rgba(0,0,0,0.1)',
    '0px 0px 10px 24px rgba(0,0,0,0.1)'
  ],
  shape: {
    borderRadius: 7
  }
});

declare module '@material-ui/core/styles/createTypography' {
  interface Typography {
    fontFamilyAlt: string;
  }

  interface TypographyOptions {
    fontFamilyAlt?: string;
  }
}

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    rating: {
      healthy: {
        aaa: string
        aa: string
        a: string
      }
      balanced: {
        bbb: string
        bb: string
        b: string
      }
      problematic: {
        ccc: string
        cc: string
        c: string
      }
      bad: {
        d: string
      }
    };
    gender: {
      female: string
      male: string
    };
    race: {
      middleEastern: string
      hispanic: string
      black: string
      asian: string
      caucasian: string
      indigenous: string
    };
    custom: {
      border: string
      appBackground: string
      primary: string
      primaryAccent: string
      primaryPale: string
      secondary: string
      secondaryAccent: string
      secondaryGrey: string
      tertiary: string
    };
  }

  interface PaletteOptions {
    rating?: {
      healthy?: {
        aaa?: string
        aa?: string
        a?: string
      }
      balanced?: {
        bbb?: string
        bb?: string
        b?: string
      }
      problematic?: {
        ccc?: string
        cc?: string
        c?: string
      }
      bad?: {
        d?: string
      }
    };
    gender?: {
      female?: string,
      male?: string
    };
    race?: {
      middleEastern?: string
      hispanic?: string
      black?: string
      asian?: string
      caucasian?: string
      indigenous?: string
    };
    custom?: {
      border?: string
      appBackground?: string
      primary?: string
      primaryAccent?: string
      primaryPale?: string
      secondary?: string
      secondaryAccent?: string
      secondaryGrey?: string
      tertiary?: string
    };
  }
}

declare module 'styled-components' {
  export interface DefaultTheme extends Theme {
  }
}