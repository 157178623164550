import React from 'react';
import {Modal} from '../../core/components/modal/Modal';
import {NotificationProvider} from '../../core/components/notification/NotificationProvider';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../core/redux/store';
import {AppState} from './redux/appDuck';
import {CriticalErrorModalContent} from './modalContent/CriticalErrorModalContent';
import {appAction} from './redux/appAction';

export const NotificationController: React.FC = () => {

  const {error, notifications} = useSelector<RootState, AppState>(state => state.app);

  // Actions
  const dispatch = useDispatch();
  const clearError = () => dispatch(appAction.clearError());

  return (
    <div>
      <Modal
        toggleModal={clearError}
        content={<CriticalErrorModalContent error={error}/>}
        show={!!error}
        width={40}
        closeOnBackdrop={false}
      />
      <NotificationProvider notifications={notifications}/>
    </div>
  );
};