import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {LinearProgress} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components/macro';
import {RequestStatus} from '../../../../core/api/api';
import {AppState} from '../../../app/redux/appDuck';
import {ButtonPrimaryOutlined} from '../../../../core/components/ui-elements/buttons';
import {TypographyStyles} from '../../../../core/styles/common/typography';
import {RootState} from '../../../../core/redux/store';
import {mainSearchAction} from '../../redux/mainSearchAction';
import {MainSearchState} from '../../redux/mainSearchDuck';
import {OptionsGeneratorVisible} from '../../../../core/components/ui-elements/optionLIst/OptionsGeneratorVisible';
import {IndustryOptions} from './IndustryOptions';

export const MainSearchContentIndustry = () => {

  // Styles
  const TS = TypographyStyles;

  // Selectors
  const {status, dictionaries} = useSelector<RootState, AppState>(state => state.app);
  const {selectedIndustry} = useSelector<RootState, MainSearchState>(state => state.mainSearch);

  const history = useHistory();

  const showDashboard = () => {
    history.push(`/database/${selectedIndustry}`);
  };

  return (
    (status !== RequestStatus.loading && dictionaries !== null) ?
      <IndustriesOptionsMain>
        <TitleWrapper>
          <TS.TitleM>Search Criteria</TS.TitleM>
        </TitleWrapper>

        <IndustryOptions showResult={showDashboard}/>

      </IndustriesOptionsMain>
      :
      <LinearProgress/>
  );
};

// Styles
const IndustriesOptionsMain = styled.div`
  background-color: ${({theme}) => theme.palette.common.white};
  border-bottom: ${({theme}) => `1.5px solid ${theme.palette.custom.border}`};
`;

const TitleWrapper = styled.div`
  position: relative;
  z-index: 100;

  margin-bottom: -6px;
  padding: .5rem 1rem;

  background-color: ${({theme}) => theme.palette.common.white};
  border: 2px solid ${({theme}) => theme.palette.custom.border};
  border-radius: ${({theme}) => theme.shape.borderRadius + 'px'};
`;