import {Form, Formik} from 'formik';
import React, {useState} from 'react';
import {countriesDictionary, IndustriesDictionary, reasonsOfInterestDictionary} from './registrationDictionaries';
import {LayoutStyles} from '../../../../core/styles/common/layout';
import {FormStyles} from '../../../../core/styles/common/form';
import {TextField} from '../../../../core/components/form/formFields/TextField';
import {PhoneField} from '../../../../core/components/form/formFields/PhoneField';
import {SelectField} from '../../../../core/components/form/formFields/SelectField';
import * as Yup from 'yup';
import {useDispatch, useSelector} from 'react-redux';
import {AuthState} from '../../redux/authDuck';
import {CheckboxField} from '../../../../core/components/form/formFields/CheckboxField';
import {TypographyStyles} from '../../../../core/styles/common/typography';
import {authAction} from '../../redux/authAction';
import {RootState} from '../../../../core/redux/store';
import {RegistrationValues} from '../../redux/authTypes';
import {ButtonPrimary} from '../../../../core/components/ui-elements/buttons';
import {AppState} from '../../../app/redux/appDuck';
import {Modal} from '../../../../core/components/modal/Modal';
import {ModalContentPrivacyPolicy} from '../../../../core/components/modalContents/ModalContentPrivacyPolicy';
import {ModalContentTermsOfUse} from '../../../../core/components/modalContents/ModalContentTermsOfUse';

export const RegistrationForm: React.FC<Props> = props => {

  //TODO: Save field values in redux before submitting to register in case of error.

  const {
    setIsRegistrationForm,
  } = props;

  // Styles
  const LS = LayoutStyles;
  const TS = TypographyStyles;
  const FS = FormStyles;

  // Selectors
  const {dictionaries} = useSelector<RootState, AppState>(state => state.app);
  const {registrationStep, registrationValuesTemp} = useSelector<RootState, AuthState>(state => state.auth);

  // Actions
  const dispatch = useDispatch();
  const register = (formValues: RegistrationValues) => dispatch(authAction.register(formValues));
  const setCurrentStep = (registrationStep: number) => dispatch(authAction.setRegistrationStep(registrationStep));

  const [modalPrivacyActive, setModalPrivacyActive] = useState<boolean>(false);
  const [modalTermsActive, setModalTermsActive] = useState<boolean>(false);

  const toggleModalPrivacy = () => {
    setModalPrivacyActive(!modalPrivacyActive);
  };

  const toggleModalTerms = () => {
    setModalTermsActive(!modalTermsActive)
  }

  const initialValues: RegistrationValues = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    companyName: '',
    industry: '',
    country: '',
    reasonOfInterest: '',
    terms: false,
  };

  const handleSubmit = (values: RegistrationValues) => {
    register(values);
  };

  const nextStep = (validateForm: any) => {

    validateForm().then((errors: any) => {
      if (Object.keys(errors).length === 0) setCurrentStep(registrationStep + 1);
    });
  };

  const previousStep = () => setCurrentStep(registrationStep - 1);

  const backToSignIn = () => {
    setIsRegistrationForm(false);
    setCurrentStep(0);
  };

  const registerFormId = 'register';

  const validationSchema = () => {
    if (registrationStep === 0) {
      return Yup.object().shape({
        firstName: Yup.string().required('first name is required'),
        lastName: Yup.string().required('last name is required'),
        email: Yup.string().email().required('email is required'),
      });
    }

    if (registrationStep === 1) {
      return Yup.object().shape({
        companyName: Yup.string().required('company name is required'),
        industry: Yup.string().required('industry is required'),
        country: Yup.string().required('country is required')
      });
    }

    if (registrationStep === 2) {
      return Yup.object().shape({
        reasonOfInterest: Yup.string().required('reason of interest is required'),
        terms: Yup.boolean().isTrue('Please agree on the terms of use').required(),
      });
    }
  };

  return (
    <Formik
      initialValues={registrationValuesTemp ? registrationValuesTemp : initialValues}
      validationSchema={validationSchema()}
      onSubmit={handleSubmit}
    >
      {({validateForm}) => (<>

        {dictionaries && <Form id={registerFormId}>

          {registrationStep === 0 && (<>
            <FS.FieldsContainer>
              <FS.FieldsRow>
                <TextField
                  label="First name*"
                  name="firstName"
                />
                <TextField
                  label="Last name*"
                  name="lastName"
                />
              </FS.FieldsRow>
              <TextField
                label="Email*"
                name="email"
              />
              <PhoneField
                label="Phone (optional)"
                name="phone"
              />
            </FS.FieldsContainer>

            <LS.ButtonWrapperRow>
              <ButtonPrimary onClick={backToSignIn}>Back to Sign In</ButtonPrimary>
              <ButtonPrimary
                onClick={() => nextStep(validateForm)}>Next
              </ButtonPrimary>
            </LS.ButtonWrapperRow>
          </>)}

          {registrationStep === 1 && (<>
            <FS.FieldsContainer>
              <TextField
                label="Company name*"
                name="companyName"
              />
              <SelectField
                label="Industry*"
                name="industry"
                options={dictionaries.industries}
              />
              <SelectField
                label="Country*"
                name="country"
                options={dictionaries.countries}
              />
            </FS.FieldsContainer>

            <LS.ButtonWrapperRow>
              <ButtonPrimary onClick={previousStep}>Back</ButtonPrimary>
              <ButtonPrimary
                onClick={() => nextStep(validateForm)}>Next</ButtonPrimary>
            </LS.ButtonWrapperRow>
          </>)}

          {registrationStep === 2 && (<>
            <Modal
              width={50}
              show={modalPrivacyActive}
              toggleModal={toggleModalPrivacy}
              content={<ModalContentPrivacyPolicy/>}
            />
            <Modal
              width={50}
              show={modalTermsActive}
              toggleModal={toggleModalTerms}
              content={<ModalContentTermsOfUse/>}
            />
            <FS.FieldsContainer>
              <SelectField
                label="Reason of interest*"
                name="reasonOfInterest"
                options={dictionaries.reasonsOfInterest}
              />

              <CheckboxField
                name="terms"
                text={<div>I acknowledge that I agree to the <TS.Link onClick={toggleModalTerms}>Terms of Use</TS.Link> and have
                  read <TS.Link onClick={toggleModalPrivacy}>Privacy Policy</TS.Link>.</div>}
              />
            </FS.FieldsContainer>

            <LS.ButtonWrapperRow>
              <ButtonPrimary onClick={previousStep}>Back</ButtonPrimary>
              <ButtonPrimary type="submit" form={registerFormId}>Create account</ButtonPrimary>
            </LS.ButtonWrapperRow>
          </>)}

          {registrationStep === 3 && (<>
            <TS.TextWrapper>
              <TS.Title>
                Welcome!
              </TS.Title>
              <div>You have successfully registered.</div>
              <div>You will shortly receive an email with further instructions.</div>
            </TS.TextWrapper>
            <ButtonPrimary onClick={backToSignIn}>
              Back to Sign In
            </ButtonPrimary>
          </>)}
        </Form>}

      </>)}
    </Formik>
  );
};

// Types
type Props = {
  setIsRegistrationForm: (value: boolean) => void
}