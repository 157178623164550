import React from 'react';
import styled from 'styled-components/macro';
import {TypographyStyles} from '../../../core/styles/common/typography';

export const NoInfo: React.FC = () => {

  // Styles
  const TS = TypographyStyles;

  return (
    <Container>
      <TitleWrapper>
        <TS.TitleS>No information on this company</TS.TitleS>
      </TitleWrapper>
    </Container>
  );
};

// Styles
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 2rem 3rem;
  width: fit-content;

  border-radius: ${({theme}) => theme.shape.borderRadius + 'px'};
  background-color: ${({theme}) => theme.palette.custom.primaryPale};
`;