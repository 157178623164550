import {call, put, select, takeLatest} from 'redux-saga/effects';
import {APIUrl, postRequest, RequestStatus} from '../../../core/api/api';
import {standardError} from '../../../helpers/sagaHelpers';
import {LocalStorageVar} from '../../../core/localStorage';
import {authAction, AuthAction, AuthActionType} from './authAction';
import {RegistrationValues, UserData} from './authTypes';
import {MainSearchState} from '../../mainSearch/redux/mainSearchDuck';

// Selectors
export const getCurrentTab = (state: { mainSearch: MainSearchState }) => state.mainSearch.activeTab;

// Workers
export function* signIn(action: ReturnType<typeof authAction.singIn>): any {

  try {
    const userData = yield call(postRequest, APIUrl.login, action.payload.signInValues);
    const currentTab = yield select(getCurrentTab);
    yield put(authAction.signInSuccess(userData, currentTab));

  } catch (error) {
    yield standardError(error, AuthActionType.SIGN_IN_FAIL);
  }
}

export function* register(action: ReturnType<typeof authAction.register>): any {
  const {formValues} = action.payload

  try {
    yield put(authAction.registrationValuesSet(formValues))
    const res = yield call(postRequest, APIUrl.register, formValues);
    yield put(authAction.registerSuccess());
  } catch (error) {
    yield standardError(error, AuthActionType.REGISTER_FAIL);
  }
}

// Watchers
export function* authWatcher() {
  yield takeLatest(AuthActionType.SIGN_IN, signIn);
  yield takeLatest(AuthActionType.REGISTER, register);
}

// Reducer
const userStorage = localStorage.getItem(LocalStorageVar.userData);

const initialState: AuthState = {
  userData: userStorage ? JSON.parse(userStorage) : null,
  registrationStep: 0,
  registrationValuesTemp: null,
  status: RequestStatus.still,
};

export function authReducer(state: AuthState = initialState, action: AuthAction): AuthState {

  switch (action.type) {

    case AuthActionType.SIGN_IN: {
      return {
        ...state,
        status: RequestStatus.loading,
      };
    }

    case AuthActionType.SIGN_IN_SUCCESS: {
      const {payload} = action;

      localStorage.setItem(LocalStorageVar.userData, JSON.stringify(payload.userData));
      localStorage.setItem(LocalStorageVar.mainSearchCurrentTab, payload.currentTab);

      return {
        ...state,
        userData: payload.userData,
        registrationValuesTemp: null,
        status: RequestStatus.still,
      };
    }

    case AuthActionType.SIGN_IN_FAIL || AuthActionType.REGISTER_FAIL: {
      return {
        ...state,
        status: RequestStatus.fail,
      };
    }

    case AuthActionType.LOGOUT: {

      localStorage.clear();

      return {
        ...state,
        userData: null,
        status: RequestStatus.still,
      };
    }

    case AuthActionType.REGISTER: {
      return {
        ...state,
        status: RequestStatus.loading,
      };
    }

    case AuthActionType.REGISTER_SUCCESS: {
      return {
        ...state,
        registrationStep: 3,
        status: RequestStatus.still,
      };
    }

    case AuthActionType.REGISTER_FAIL: {
      return {
        ...state,
        status: RequestStatus.fail,
      };
    }

    case AuthActionType.SET_REGISTRATION_STEP: {
      const {payload} = action;

      return {
        ...state,
        registrationStep: payload.registrationStep
      };
    }

    case AuthActionType.REGISTRATION_VALUES_SET: {
      const {payload} = action;

      return {
        ...state,
        registrationValuesTemp: payload.values
      }
    }

    default:
      return state;
  }
}

// Types
export type AuthState = {
  userData: UserData | null
  registrationStep: number
  registrationValuesTemp: RegistrationValues | null
  status: RequestStatus
}

