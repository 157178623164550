import styled from 'styled-components/macro';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  
  min-height: 100vh;
`;

const Inner = styled.div`
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
`;

const GridColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const GridRow = styled.div`
  display: flex;
  gap: 1rem;

  width: 100%;
`;

const Paper = styled.div`
  padding: 1rem;

  width: 100%;

  background: ${({theme}) => theme.palette.common.white};
  border-radius: ${({theme}) => theme.shape.borderRadius + 'px'};
`;

const PaperBW = styled(Paper)`
  border: 1px solid ${({theme}) => theme.palette.grey[200]};
`;

const PaperColor = styled(Paper)`
  border: 1px solid ${({theme}) => theme.palette.custom.border};
`;

const Card = styled.div`
  padding: 1rem;

  width: 100%;

  background: ${({theme}) => theme.palette.common.white};
  box-shadow: ${({theme}) => theme.shadows[1]};
  border-radius: ${({theme}) => theme.shape.borderRadius + 'px'};
`;

const PreloaderWrapperPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100vh;
`;

const PreloaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
`;

// Button wrappers
const ButtonWrapperColumn = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  & button:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const ButtonWrapperRow = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;

  & button:not(:last-child) {
    margin-right: 1rem;
  }
`;

export const LayoutStyles = {
  Container,
  Inner,
  GridColumn,
  GridRow,
  PaperBW,
  PaperColor,
  Card,
  PreloaderWrapperPage,
  PreloaderWrapper,
  ButtonWrapperColumn,
  ButtonWrapperRow
};
