import React from 'react';
import styled from 'styled-components/macro';

export const LogoCombined: React.FC<Props> = props => {

  const {
    onClick,
    className
  } = props;

  return (
    <Container className={className} onClick={onClick}>
      <FqImg src="/fq_logo.png" alt={'FQ logo'}/>
      <DenominatorImg src="/denominator_logo.png" alt={'Denominator logo'}/>
    </Container>
  );
};

// Styles
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FqImg = styled.img`
  position: relative;
  left: -1%;
  
  padding-bottom: .2rem;

  max-width: 80%;
`;

const DenominatorImg = styled.img`
  max-width: 100%;
`

// Types
type Props = {
  onClick?: (e: React.SyntheticEvent) => void
  className?: string
}
