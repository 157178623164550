import React, {useEffect} from 'react';
import {SearchBar} from './components/SearchBar';
import styled from 'styled-components/macro';
import {useDispatch, useSelector} from 'react-redux';
import {MainSearchState} from './redux/mainSearchDuck';
import {MainSearchContentIndustry} from './components/content/MainSearchContentIndustry';
import {MainSearchContentCompany} from './components/content/MainSearchContentCompany';
import {Header} from '../../core/components/layout/Header/Header';
import {LogoHeader} from '../../core/components/ui-elements/logos/LogoHeader';
import {TypographyStyles} from '../../core/styles/common/typography';
import {RootState} from '../../core/redux/store';
import {AuthState} from '../auth/redux/authDuck';
import {mainSearchAction} from './redux/mainSearchAction';
import {SearchTab} from './redux/mainSearchTypes';
import {appAction} from '../app/redux/appAction';
import {ButtonPrimary, ButtonPrimaryOutlined} from '../../core/components/ui-elements/buttons';
import {useHistory} from 'react-router-dom';

export const MainSearchPage: React.FC = () => {

  const history = useHistory();

  // Styles
  const TS = TypographyStyles;

  // Selectors
  const {userData} = useSelector<RootState, AuthState>(state => state.auth);
  const {activeTab} = useSelector<RootState, MainSearchState>(state => state.mainSearch);

  // Actions
  const dispatch = useDispatch();
  const initializeApp = () => dispatch(appAction.initialize());
  const setTab = (activeTab: SearchTab) => dispatch(mainSearchAction.tabSet(activeTab));

  useEffect(() => {
    initializeApp();
    if (!userData) {
      setTab('industry');
    }

  }, [userData]);

  return (
    <MainSearchMain>

      {userData
        ?
        <Header/>
        :
        <HeaderNoUser>
          <TS.TextWrapper>
            <TS.Title size="xxxl">FQ Equity Index</TS.Title>
          </TS.TextWrapper>

          <HeaderNoUserRight>
            <ButtonPrimaryOutlined onClick={() => history.push('/auth')}>Login / Register</ButtonPrimaryOutlined>
            <LogoHeader/>
          </HeaderNoUserRight>

        </HeaderNoUser>
      }

      <Container>
        <SearchBarWrapper>
          <SearchBar/>
        </SearchBarWrapper>

        {activeTab === 'industry' && <MainSearchContentIndustry/>}
        {userData && activeTab === 'company' && <MainSearchContentCompany/>}

      </Container>
    </MainSearchMain>
  );
};

// Styles
const MainSearchMain = styled.div`
  display: flex;
  flex-direction: column;

  min-height: 100vh;
`;

const HeaderNoUser = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 2rem 2rem 0 2rem;
`;

const HeaderNoUserRight = styled.div`
  display: flex;
  
  & button {
    margin-right: 3rem;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  padding: 2rem;

  height: 100%;
  width: 100vw;
`;

const SearchBarWrapper = styled.div`
  margin-bottom: 1.5rem;

  background: ${({theme}) => theme.palette.custom.primaryPale};
  border-radius: ${({theme}) => theme.shape.borderRadius + 'px'};
`;