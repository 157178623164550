import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {DatabaseState} from './redux/databaseDuck';
import {RequestStatus} from '../../core/api/api';
import {LayoutStyles} from '../../core/styles/common/layout';
import {TypographyStyles} from '../../core/styles/common/typography';
import styled from 'styled-components/macro';
import {Preloader} from '../../core/components/ui-elements/loaders/Preloader';
import {AppState} from '../app/redux/appDuck';
import {ButtonPrimaryOutlined} from '../../core/components/ui-elements/buttons';
import {MainSearchState} from '../mainSearch/redux/mainSearchDuck';
import {IndustryPerformanceChartVertical} from './components/ratingCards/IndustryPerformanceChart/IndustryPerformanceChartVertical';
import {RadialRatingCard} from './components/ratingCards/RadialRatingCard';
import {BenchmarkCard} from './components/ratingCards/BenchmarkCard';
import {Header} from '../../core/components/layout/Header/Header';
import {LogoHeader} from '../../core/components/ui-elements/logos/LogoHeader';
import {NoInfo} from './components/NoInfo';
import {RootState} from '../../core/redux/store';
import {AuthState} from '../auth/redux/authDuck';
import {Modal} from '../../core/components/modal/Modal';
import {ModalContentContactUs} from '../../core/components/modalContents/ModalContentContactUs';
import {DatabaseRequest, IndustryGraphItem} from './redux/databaseTypes';
import {databaseAction} from './redux/databaseAction';
import {useWindowDimensions} from '../../hooks/useWindowDimensions';
import {theme} from '../../core/styles/theme';
import {IndustryPerformanceChartHorizontal} from './components/ratingCards/IndustryPerformanceChart/IndustryPerformanceChartHorizontal';

export const DatabasePage: React.FC = () => {

  const routeParams = useParams() as { id: string };
  const history = useHistory();

  // Styles
  const TS = TypographyStyles;
  const LS = LayoutStyles;

  // Selectors
  const {databaseData, status} = useSelector<RootState, DatabaseState>(state => state.database);
  const {activeTab, selectedIndustry} = useSelector<RootState, MainSearchState>(state => state.mainSearch);
  const {dictionaries} = useSelector<RootState, AppState>(state => state.app);
  const {userData} = useSelector<RootState, AuthState>(state => state.auth);

  const activeIndustry = selectedIndustry ? selectedIndustry : 2000;

  // Actions
  const dispatch = useDispatch();
  const databaseLoad = (databaseRequest: DatabaseRequest) => dispatch(databaseAction.databaseLoad(databaseRequest));
  const clearDatabase = () => dispatch(databaseAction.databaseClear());

  const mode = databaseData && databaseData.mode;

  const isIndustryTab = activeTab === 'industry';
  const isCompanyTab = activeTab === 'company';

  const getUrl = () => {
    if (isIndustryTab) return 'fqIndustries';
    if (isCompanyTab) return 'fqCompany';
    return '';
  };

  // Local state
  const [peerGroup, setPeerGroup] = useState<IndustryGraphItem | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const toggleModal = () => setShowModal(!showModal);

  const industryDataRequest = () => {
    const databaseRequest = {
      url: getUrl(),
      params: `industry_id=${routeParams.id}`,
      body: null
    };

    databaseLoad(databaseRequest);
  };

  const companyDataRequest = () => {
    const databaseRequest = {
      url: getUrl(),
      params: `company_id=${routeParams.id}&industry_id=${activeIndustry}`,
      body: null
    };

    databaseLoad(databaseRequest);
  };

  const {width} = useWindowDimensions();

  useEffect(() => {

    if (isIndustryTab) {
      industryDataRequest();
    }

    if (isCompanyTab && userData) {
      companyDataRequest();
    }

    if (isCompanyTab && !userData) {
      history.push('/main');
    }

    return () => {
      clearDatabase();
    };

  }, [userData, routeParams.id]);

  useEffect(() => {
    if (isCompanyTab && userData && databaseData) {
      setPeerGroup(databaseData.industryGraph.find((item: IndustryGraphItem) => item.isSelected));
    }
  }, [databaseData]);


  return (<>
    {
      status === RequestStatus.still && dictionaries && databaseData
        ?
        <>
          {userData && <Header/>}
          <Modal
            toggleModal={toggleModal}
            content={<ModalContentContactUs/>}
            show={showModal}
            width={40}
            closeOnBackdrop={false}
          />
          {isIndustryTab && (<>
            <Container>
              <TitleContainer>

                <div>
                  <TS.TextWrapper>
                    <TS.TitleUppercaseXXL>
                      Industry DEI Index
                    </TS.TitleUppercaseXXL>
                  </TS.TextWrapper>

                  <TS.TextWrapper>
                    <TS.SubtitleL>Industry DEI performance across Gender, Race/Ethnicity, Age, Sexuality,</TS.SubtitleL>
                    <TS.SubtitleL>Disability, Family, Education and other DEI sub-dimensions.</TS.SubtitleL>
                  </TS.TextWrapper>
                </div>

                {!userData && <LogoHeader/>}

              </TitleContainer>

              <CardStyledIndustry>

                {databaseData.industryGraph &&
                width > theme.breakpoints.values.md
                  ? <IndustryPerformanceChartVertical industryOptionsShowResultButtonAction={companyDataRequest}/>
                  : <IndustryPerformanceChartHorizontal industryOptionsShowResultButtonAction={companyDataRequest}/>
                }

                {!userData && (
                  <ButtonWrapper>
                    <ButtonPrimaryOutlinedStyledIndustry onClick={() => history.push('/auth')}>
                      Check my own position against the industry performance
                    </ButtonPrimaryOutlinedStyledIndustry>
                  </ButtonWrapper>
                )}

              </CardStyledIndustry>
            </Container>
          </>)}

          {isCompanyTab && (
            <Container>
              <LS.GridColumn>

                <LS.GridRow>
                  <LS.Card>
                    <TS.SubtitleS>
                      COMPANY NAME:
                    </TS.SubtitleS>

                    <TS.TitleUppercaseXXL>
                      {databaseData.companyHeader.name}
                    </TS.TitleUppercaseXXL>
                  </LS.Card>
                </LS.GridRow>

                <GridRowStyled>
                  <RadialRatingCard
                    title="Company DEI Index"
                    data={mode === 'advanced' ? databaseData.advancedTotalRating : databaseData.derivedRating}
                  />
                  {peerGroup
                    ?
                    // TODO: peerGroup is a temporary fix for lacking peerGroup information. Remove when API is fixed.
                    <BenchmarkCard
                      title="Benchmark DEI Index"
                      data={databaseData.peerIndustryBenchmark}
                      peerGroup={peerGroup.rating}
                    />
                    :
                    <LS.Card>
                      <TS.TextWrapper>
                        <TS.TitleM>Benchmark DEI Index</TS.TitleM>
                      </TS.TextWrapper>
                      <NoInfo/>
                    </LS.Card>
                  }
                </GridRowStyled>

                <CardStyledCompany>
                  <HeaderInner>
                    <TS.TextWrapper>
                      <TS.TitleM>
                        Industry DEI Index
                      </TS.TitleM>
                    </TS.TextWrapper>

                    <ButtonPrimaryOutlinedStyled onClick={toggleModal}>
                      Click here to close a gap
                    </ButtonPrimaryOutlinedStyled>
                  </HeaderInner>

                  {databaseData.industryGraph
                    ?
                    width > theme.breakpoints.values.md
                      ? <IndustryPerformanceChartVertical industryOptionsShowResultButtonAction={companyDataRequest}/>
                      : <IndustryPerformanceChartHorizontal industryOptionsShowResultButtonAction={companyDataRequest}/>
                    :
                    <NoInfo/>
                  }
                </CardStyledCompany>

              </LS.GridColumn>
            </Container>
          )}

        </>
        :
        <LS.PreloaderWrapperPage>
          <Preloader/>
        </LS.PreloaderWrapperPage>
    }
  </>);
};

// Styles
const Container = styled.div`
  padding: 2rem;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
`;

const GridRowStyled = styled(LayoutStyles.GridRow)`
  flex-wrap: wrap;

  & > div {
    flex-basis: 40rem;
    flex-grow: 1;
  }
`;

const HeaderInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;

  margin-bottom: 4rem;
`;

const CardStyledIndustry = styled(LayoutStyles.Card)`
  padding: 1rem;
`;

const CardStyledCompany = styled(LayoutStyles.Card)`
  padding: 1rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const ButtonPrimaryOutlinedStyledIndustry = styled(ButtonPrimaryOutlined)`
  width: 19rem;
`;

const ButtonPrimaryOutlinedStyled = styled(ButtonPrimaryOutlined)`
  width: 13rem;
`;