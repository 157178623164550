import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components/macro';
import {Arrow} from '../../../ui-elements/Arrow';
import {createAction} from '../../../../redux/createAction';
import {ButtonRounded} from '../../../ui-elements/buttons';
import {useHistory} from 'react-router-dom';
import {AuthState} from '../../../../../features/auth/redux/authDuck';
import {AuthActionType} from '../../../../../features/auth/redux/authAction';
import {RootState} from '../../../../redux/store';

export const HeaderProfile = () => {

  const history = useHistory()

  // Selectors
  const {userData} = useSelector<RootState, AuthState>(state => state.auth)

  // Actions
  const dispatch = useDispatch();
  const logout = () => dispatch(createAction(AuthActionType.LOGOUT));

  const [open, setOpen] = useState<boolean>(false);

  return (
    <ProfileMain
      isOpen={open}
      loggedIn={!!userData}
    >
      <UserNameContainer
        onClick={() => setOpen(!open)}>
        <UserName>User menu</UserName>
        <ArrowContainer>
          <Arrow
            direction={open ? 'right' : 'left'}
          />
        </ArrowContainer>
      </UserNameContainer>

      {userData
        ?
        <ButtonRounded onClick={logout}>
          Logout
        </ButtonRounded>
        :
        <ButtonRounded onClick={() => history.push('/auth')}>
          Login / Register
        </ButtonRounded>
      }
    </ProfileMain>
  );
};

// Styles
const ProfileMain = styled.div<{ isOpen: boolean, loggedIn: boolean }>`
  display: flex;
  align-items: center;
  gap: 1.2rem;

  transition: 500ms;
  transform: ${({isOpen, loggedIn}) => isOpen ? 'translateX(0)' : loggedIn ? 'translateX(10rem)' : 'translateX(14.5rem)'};
`;

const UserNameContainer = styled.div`
  display: flex;

  margin-right: 1rem;

  &:hover {
    cursor: pointer;
  }
`;

const UserName = styled.span`
  margin-right: .5rem;

  font-size: 1.2rem;
  line-height: 1.4rem;
`;

const ArrowContainer = styled.div`
  fill: ${({theme}) => theme.palette.grey[500]};
`;