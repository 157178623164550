import React from 'react';
import {LayoutStyles} from '../../../../core/styles/common/layout';
import {SingleRating} from '../../../../core/components/ratings/components/SingleRating';
import {Benchmark} from '../../../../core/components/ratings/benchmark/Benchmark';
import styled from 'styled-components/macro';
import {TypographyStyles} from '../../../../core/styles/common/typography';

export const BenchmarkCard: React.FC<Props> = props => {

  const {
    title,
    data,
    peerGroup
  } = props;

  // Styles
  const TS = TypographyStyles;
  const LS = LayoutStyles;

  const scoreValue = data.companyRating ? data.companyRating.score : data.score;

  return (
    <LS.Card>
      <TS.TextWrapper>
        <TS.TitleM>{title}</TS.TitleM>
      </TS.TextWrapper>

      <Benchmark data={data} peerGroup={peerGroup}/>
    </LS.Card>
  );
};

// Types
type Props = {
  title: string
  data: PeerBenchmarkData & BenchmarkData
  peerGroup?: number
}

type PeerBenchmarkData = {
  companyRating?: {
    quality?: string
    rating?: string
    score?: number
  }
  peerRating?: {
    rating?: string
    score?: number
    strength?: number
  }
}

type BenchmarkData = {
  score?: number
  rating?: string
}