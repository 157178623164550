import React from 'react';
import * as Yup from 'yup';
import {Form, Formik} from 'formik';
import {TextField} from '../../../core/components/form/formFields/TextField';
import {PasswordField} from '../../../core/components/form/formFields/PasswordField';
import {ButtonPrimary, ButtonPrimaryOutlined} from '../../../core/components/ui-elements/buttons';
import {useDispatch} from 'react-redux';
import {FormStyles} from '../../../core/styles/common/form';
import {LayoutStyles} from '../../../core/styles/common/layout';
import {authAction} from '../redux/authAction';
import {SignInValues} from '../redux/authTypes';

export const SignInForm: React.FC<Props> = props => {

  const {
    setIsRegistrationForm
  } = props

  // Styles
  const LS = LayoutStyles;
  const FS = FormStyles;

  const initialValues = {
    username: '',
    password: '',
  };

  // Actions
  const dispatch = useDispatch();
  const loginUser = (signInValues: SignInValues) => dispatch(authAction.singIn(signInValues));

  const handleSubmit = (values: SignInValues) => {
    loginUser(values);
  };

  const signInFormId = 'signIn';

  return (
    <Formik
      initialValues={initialValues}
      initialErrors={initialValues}
      validationSchema={Yup.object().shape({
        username: Yup.string().required(),
        password: Yup.string().min(6, 'At least 6 characters long').required(),
      })}
      onSubmit={handleSubmit}
    >
      <Form id={signInFormId}>
        <FS.FieldsContainer>
          <TextField label="Login" name="username"/>
          <PasswordField label="Password" name="password"/>
        </FS.FieldsContainer>

        <LS.ButtonWrapperColumn>

          <ButtonPrimary type="submit" form={signInFormId}>
            Sign in
          </ButtonPrimary>

          <ButtonPrimaryOutlined onClick={() => setIsRegistrationForm(true)}>
            Register
          </ButtonPrimaryOutlined>

        </LS.ButtonWrapperColumn>
      </Form>
    </Formik>
  );
};

// Types
type Props = {
  setIsRegistrationForm: (value: boolean) => void
}