import React, {useEffect, useState} from 'react';
import styled from 'styled-components/macro';
import {LogoCombined} from '../../core/components/ui-elements/logos/LogoCombined';
import {LayoutStyles} from '../../core/styles/common/layout';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {SignInForm} from './forms/SignInForm';
import {Preloader} from '../../core/components/ui-elements/loaders/Preloader';
import {RequestStatus} from '../../core/api/api';
import {RootState} from '../../core/redux/store';
import {AuthState} from './redux/authDuck';
import {mainSearchAction} from '../mainSearch/redux/mainSearchAction';
import {SearchTab} from '../mainSearch/redux/mainSearchTypes';
import {RegistrationForm} from './forms/RegistrationForm/RegistrationForm';

export const AuthPage: React.FC = () => {

  const history = useHistory();

  // Selectors
  const {status, userData} = useSelector<RootState, AuthState>(state => state.auth);

  // Actions
  const dispatch = useDispatch();
  const setTab = (activeTab: SearchTab) => dispatch(mainSearchAction.tabSet(activeTab));

  const [isRegistrationForm, setIsRegistrationForm] = useState<boolean>(false);

  useEffect(() => {
    if (userData) {
      setTab('company');
      history.push('/main');
    }
  }, [userData]);


  return (
    <Container>
      <CardStyled>
        <LogoCard>
          <LogoStyled/>
        </LogoCard>
        {status === RequestStatus.loading
          ?
          <PreloaderContainer>
            <Preloader/>
          </PreloaderContainer>
          :
          <>
            {isRegistrationForm
              ?
              <RegistrationForm setIsRegistrationForm={setIsRegistrationForm}/>
              :
              <SignInForm
                setIsRegistrationForm={setIsRegistrationForm}/>
            }
          </>}
      </CardStyled>
    </Container>
  );
};

// Styles
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100vh;
`;

const CardStyled = styled(LayoutStyles.Card)`
  display: flex;
  flex-direction: column;

  padding: 1.5rem 3rem 5rem;

  width: 35rem;
`;

const LogoCard = styled.div`
  padding: 1.2rem 10rem;
  margin-bottom: 1.5rem;

  background-color: ${({theme}) => theme.palette.custom.primaryPale};
  border-radius: ${({theme}) => theme.shape.borderRadius + 'px'};
`;

const LogoStyled = styled(LogoCombined)`
  display: flex;

  & img {
    width: 100%;
  }
`;

const PreloaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
`;