import ReactDOM from 'react-dom'
import React from 'react'
import styled from 'styled-components/macro'

export const Backdrop: React.FC<Props> = props => {

    const {
        isActive,
        onClick
    } = props

    const content = <BackDropMain isActive={isActive} onClick={onClick}>{props.children}</BackDropMain>
    const container = document.getElementById('backdrop-portal') as HTMLElement

    return ReactDOM.createPortal(content, container)
}

// Styles
const BackDropMain = styled.div<{ isActive: boolean }>`
  display: ${({isActive}) => isActive ? 'block' : 'none'};
  
  position: fixed;
  z-index: 1000;

  height: 100%;
  width: 100vw;

  background-color: rgba(.5, .5, .5, .5);
`

// Types
type Props = {
    isActive: boolean
    onClick: any
}